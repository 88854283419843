import React, { Component } from 'react';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import { Button, Container } from 'reactstrap';
import { getPersonProfile } from './OpenGradaranApi.js';
import {
  EmptyPersonProfile,
  EmptyAddress,
  EmptyContact,
  canEditContent,
} from './Constant.js';
import { backgroundStyle } from './InlineStyles.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import AddressView from './AddressView.js';
import ContactView from './ContactView.js';
import { ViewTitle, BackButton } from './utils/CustomViewUtil.js';
import { withTranslation } from 'react-i18next';
import { AppContext } from './AppContext.js';
import { getFullName } from './utils/TextUtil.js';

/* This is a higher order component that
 *  inject a special prop to our component.
 */
function withRouter(Component) {
  function ComponentWithRouter(props) {
    let params = useParams();
    let navigate = useNavigate();
    let location = useLocation();
    return (
      <Component
        {...props}
        params={params}
        navigate={navigate}
        location={location}
      />
    );
  }
  return ComponentWithRouter;
}

class PersonProfilePage extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);

    this.state = {
      personProfile: { ...EmptyPersonProfile },
    };
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
  }

  async componentDidMount() {
    const id = this.props.params.id;
    var personProfile = this.props.location.state?.personProfile;
    if (!personProfile && id) {
      const personProfileResp = await (await getPersonProfile(id)).json();
      if (personProfileResp.message == null) {
        personProfile = personProfileResp;
      } else {
        // handle error
      }
    }
    this.setState({ personProfile: personProfile || EmptyPersonProfile });
  }

  handleEditClick(event) {
    event.preventDefault();
    console.log('Icon clicked!');
    const { personProfile } = this.state;
    this.props.navigate(`/person-profiles/${personProfile.id}`, {
      state: { personProfile },
    });
  }

  handleGoBack = () => {
    this.props.navigate('/person-profiles');
  };

  render() {
    const { t } = this.props;
    const { appState } = this.context;
    const { personProfile } = this.state;

    const address = personProfile.address;
    const contactGroupList = personProfile.contacts.map((contact, i) => {
      return (
        <div>
          <p>
            <ContactView contact={contact} personProfile={personProfile} />
          </p>
        </div>
      );
    });
    const addressView = address ? (
      <AddressView
        address={personProfile.address}
        personProfile={personProfile}
      />
    ) : (
      <p>
        <Button
          color='primary'
          tag={Link}
          to={'/addresses/new'}
          state={{
            personProfile,
            address: { ...EmptyAddress },
          }}
        >
          {t('Add Address')}
        </Button>
      </p>
    );

    return (
      <div>
        <div style={backgroundStyle}>
          <Container className='subview-container-blue'>
            <div style={{ position: 'relative', padding: '30px' }}>
              <ViewTitle
                name={getFullName(personProfile)}
                resourceName={'PersonProfile'}
              />
              {personProfile.occupation && (
                <p>
                  {t('Occupation')}: {personProfile.occupation}
                </p>
              )}
              {personProfile.organization && (
                <p>
                  {t('Organization')}: {personProfile.organization?.name}
                </p>
              )}
              {personProfile.alpParticipant && <p>{t('ALP Participant')}</p>}
              {addressView}

              {contactGroupList}
              <p>
                <Button
                  color='primary'
                  tag={Link}
                  to={'/contacts/new'}
                  state={{
                    personProfile,
                    contact: { ...EmptyContact },
                  }}
                >
                  {t('Add Contact')}
                </Button>
              </p>

              <p>
                <BackButton onClick={this.handleGoBack} />
              </p>
              {/* Edit icon at the upper right corner */}
              {canEditContent() && (
                <div className='edit-pencil-container'>
                  <a href='' onClick={this.handleEditClick}>
                    <FontAwesomeIcon
                      icon={faPencilAlt}
                      className='edit-pencil'
                    />
                  </a>
                </div>
              )}
            </div>
          </Container>
        </div>
      </div>
    );
  }
}
const HOCPersonProfilePage = withRouter(withTranslation()(PersonProfilePage));
export default HOCPersonProfilePage;

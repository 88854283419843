import React, { Component } from 'react';
import { Button, ButtonGroup, Container, Table } from 'reactstrap';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  getShippingBoxesByOrganizationId,
  getShippingBoxesByDonationEventId,
  deleteShippingBox,
} from './OpenGradaranApi.js';
import { PageFooter, BackArrow } from './utils/CustomViewUtil.js';
import {
  EmptyOrganization,
  EmptyDonationEvent,
  EmptyShippingBox,
  ResourceName,
  canEditContent,
} from './Constant.js';
import { AppContext } from './AppContext.js';
import { withTranslation } from 'react-i18next';
import { getFullName } from './utils/TextUtil.js';

/* This is a higher order component that
 *  inject a special prop   to our component.
 */
function withRouter(Component) {
  function ComponentWithRouter(props) {
    let params = useParams();
    let navigate = useNavigate();
    let location = useLocation();
    return (
      <Component
        {...props}
        params={params}
        navigate={navigate}
        location={location}
      />
    );
  }
  return ComponentWithRouter;
}

class ShippingBoxListPaginated extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);

    //const { organization, donationEvent } = this.props;

    this.state = {
      isLoading: false,
      organization: { ...EmptyOrganization },
      donationEvent: { ...EmptyDonationEvent },
      shippingBoxes: [],
      currentPage: 0,
      totalPageCount: 0,
      totalElementCount: 0,
      pageOffset: 0,
      pageSize: 0,
    };
    this.remove = this.remove.bind(this);
    this.paginate = this.paginate.bind(this);
  }

  async componentDidMount() {
    const { appState } = this.context;
    const selectedPage =
      appState.selectedResource === ResourceName.SHIPPING_BOX
        ? appState.selectedPage
        : 0;

    const donationEvent = this.props.location.state?.donationEvent;
    var organization =
      donationEvent?.organization || this.props.location.state?.organization;

    if (organization) {
      this.setState(
        {
          organization: organization || EmptyOrganization,
          donationEvent: donationEvent || EmptyDonationEvent,
        },
        () => {
          // This code will be executed after the state has been updated
          this.setState({ isLoading: true });
          this.paginate({ selected: selectedPage });
        }
      );
    } else {
      this.setState({ isLoading: true });
      this.paginate({ selected: selectedPage });
    }
  }

  async remove(id) {
    // Display a confirmation dialog before proceeding with the delete action
    const isConfirmed = window.confirm(
      'Are you sure you want to delete this entry?'
    );

    if (!isConfirmed) {
      // If the user cancels, exit the function
      return;
    }

    this.setState({ isLoading: true });
    await deleteShippingBox(id).then((shippingBoxResponse) => {
      if (shippingBoxResponse.message == null) {
        let updatedShippingBoxes = [...this.state.shippingBoxes].filter(
          (i) => i.id !== id
        );
        this.setState({
          shippingBoxes: updatedShippingBoxes,
          isLoading: false,
        });
      } else {
        this.setState({ isLoading: false });
        //report error
      }
    });
  }

  async paginate({ selected }) {
    const { updateAppSelectedPage } = this.context;
    updateAppSelectedPage(ResourceName.SHIPPING_BOX, selected);

    const { organization, donationEvent } = this.state;
    this.setState({ currentPage: selected });

    var shippingBoxPageResponse;
    if (donationEvent.id) {
      shippingBoxPageResponse = await (
        await getShippingBoxesByDonationEventId(selected, donationEvent.id)
      ).json();
    } else if (organization.id) {
      shippingBoxPageResponse = await (
        await getShippingBoxesByOrganizationId(selected, organization.id)
      ).json();
    }
    if (shippingBoxPageResponse?.message == null) {
      const respData = shippingBoxPageResponse.data;
      this.setState((state) => {
        return {
          isLoading: false,
          organization: organization,
          donationEvent: donationEvent,
          shippingBoxes: respData.page,
          currentPage: selected,
          totalPageCount: respData.totalPages,
          totalElementCount: respData.totalElements,
          pageOffset: respData.offset,
          pageSize: respData.pageSize,
        };
      });
    } else {
      this.setState({ isLoading: false });
    }
  }

  render() {
    const { t } = this.props;
    const { appState } = this.context;
    const {
      isLoading,
      organization,
      donationEvent,
      shippingBoxes,
      currentPage,
      totalElementCount,
      totalPageCount,
      pageSize,
      pageOffset,
    } = this.state;

    if (isLoading) {
      return <p>Loading...</p>;
    }

    const addBoxButton =
      canEditContent() && donationEvent?.id ? (
        <Button
          color='success'
          tag={Link}
          to='/shipping-boxes/new'
          state={{
            organization: organization,
            donationEvent: donationEvent,
            shippingBox: { ...EmptyShippingBox },
          }}
        >
          {t('Add Shipping Box')}
        </Button>
      ) : null;

    const shippingBoxList = shippingBoxes.map((shippingBox) => {
      return (
        <tr key={shippingBox.id}>
          {/* <td style={{ whiteSpace: 'nowrap' }}>{shippingBox.edition.title}</td> */}
          <td style={{ whiteSpace: 'nowrap' }}>
            <Link
              to={`/shipping-boxes/page/${shippingBox.id}`}
              state={{ shippingBox, organization, donationEvent }}
              className='button-link-left'
            >
              {shippingBox.id}
              {/* ShippingBox #{shippingBox.id} */}
            </Link>
          </td>
          <td>
            <Link
              to={`/donation-events/page/${shippingBox.donationEvent?.id}`}
              state={{ donationEvent: shippingBox.donationEvent }}
            >
              {shippingBox.donationEvent?.name}
            </Link>
          </td>
          <td>{shippingBox.trackingNumber}</td>
          <td>{shippingBox.tag}</td>
          <td>{getFullName(shippingBox.sender)}</td>
          <td>{getFullName(shippingBox.recipient)}</td>
          <td>{shippingBox.shippingStatus}</td>
          {/* <td>{shippingBox.status}</td> */}
          {canEditContent() && (
            <td>
              <ButtonGroup>
                <Button
                  size='sm'
                  color='primary'
                  tag={Link}
                  to={`/shipping-boxes/${shippingBox.id}`}
                  state={{ shippingBox, organization, donationEvent }}
                >
                  {t('Edit')}
                </Button>
                <Button
                  size='sm'
                  color='danger'
                  onClick={() => this.remove(shippingBox.id)}
                >
                  {t('Delete')}
                </Button>
              </ButtonGroup>
            </td>
          )}
        </tr>
      );
    });

    return (
      <div>
        <Container fluid className='paginated-list-container'>
          <BackArrow />
          <div>{addBoxButton}</div>
          <br />
          <h3>{organization?.name}</h3>
          <h4>
            {donationEvent?.name}: {t('Shipping Boxes')}
          </h4>
          <Table className='mt-4'>
            <thead>
              <tr>
                <th width='5%'>ID</th>
                <th width='15%'>{t('Donation Event')}</th>
                <th width='10%'>{t('Tracking Number')}</th>
                <th width='10%'>{t('Tag')}</th>
                <th width='10%'>{t('Sender')}</th>
                <th width='10%'>{t('Recipient')}</th>
                <th width='10%'>{t('Shipping Status')}</th>
                {/* <th width='10%'>{t('Status')}</th> */}
                {canEditContent() && <th width='20%'>{t('Actions')}</th>}
              </tr>
            </thead>
            <tbody>{shippingBoxList}</tbody>
          </Table>
          <PageFooter
            totalPageCount={totalPageCount}
            currentPage={currentPage}
            paginate={this.paginate}
          />
        </Container>
      </div>
    );
  }
}
const HOCShippingBoxListPaginated = withRouter(
  withTranslation()(ShippingBoxListPaginated)
);
export default HOCShippingBoxListPaginated;

import React from 'react';
import ReactDOM from 'react-dom';
import jsPDF from 'jspdf';
import * as htmlToImage from 'html-to-image';
import AlpQRCode from './utils/AlpQRCode';

const GeneratePDFWithAlpQRCodes = () => {
  const generatePDFWithQRCodes = async () => {
    const qrCodeSize = 200; // QR code size in mm
    const spacing = 10; // Spacing between QR codes in mm
    const pageWidth = 210; // A4 width in mm
    const pageHeight = 297; // A4 height in mm
    const startX = (pageWidth - qrCodeSize - spacing) / 2; // Center the grid horizontally
    const startY = 5; // Reduced startY margin from 50 to 5 mm for better use of space
    const rowHeight = qrCodeSize + spacing; // Height for each row

    const doc = new jsPDF();

    // Calculate the X and Y position of each QR code
    const x = startX;
    const y = startY;

    // Create a temporary DOM node to render BookQRCode
    const container = document.createElement('div');
    document.body.appendChild(container);

    // Render BookQRCode into the temporary DOM node
    await new Promise((resolve) => {
      ReactDOM.render(<AlpQRCode />, container, resolve);
    });

    const qrCodeDiv = container.firstChild;

    // Wait for QRCodeCanvas to fully render
    await new Promise((r) => setTimeout(r, 200)); // Adjust delay as needed

    // Capture the rendered content as an image
    await htmlToImage.toCanvas(qrCodeDiv).then((finalCanvas) => {
      const imgData = finalCanvas.toDataURL('image/png');
      doc.addImage(imgData, 'PNG', x, y, qrCodeSize, qrCodeSize);
    });

    // Clean up temporary DOM node
    ReactDOM.unmountComponentAtNode(container);
    document.body.removeChild(container);

    // Save the PDF
    doc.save('AlpQRCodes.pdf');
  };

  return (
    <div>
      <button onClick={() => generatePDFWithQRCodes()}>Generate PDF</button>
    </div>
  );
};

export default GeneratePDFWithAlpQRCodes;

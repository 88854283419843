import React, { Component } from 'react';
import { Button, ButtonGroup, Container, Table } from 'reactstrap';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  getDonationEventsByOrganizationId,
  deleteDonationEvent,
  getOrganization,
} from './OpenGradaranApi.js';
import { PageFooter, BackArrow } from './utils/CustomViewUtil.js';
import { formatDateMonthYear } from './utils/DateTimeUtil.js';
import {
  EmptyOrganization,
  EmptyDonationEvent,
  ResourceName,
  canEditContent,
} from './Constant';
import { AppContext } from './AppContext.js';
import { withTranslation } from 'react-i18next';
import {
  getText,
  createTranslator,
  CaseFormat,
} from './utils/TranslationUtil.js';

/* This is a higher order component that
 *  inject a special prop   to our component.
 */
function withRouter(Component) {
  function ComponentWithRouter(props) {
    let params = useParams();
    let navigate = useNavigate();
    let location = useLocation();
    return (
      <Component
        {...props}
        params={params}
        navigate={navigate}
        location={location}
      />
    );
  }
  return ComponentWithRouter;
}

class DonationEventListPaginated extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      organization: { ...EmptyOrganization },
      donationEvents: [],
      currentPage: 0,
      totalPageCount: 0,
      totalElementCount: 0,
      pageOffset: 0,
      pageSize: 0,
    };
    this.remove = this.remove.bind(this);
    this.paginate = this.paginate.bind(this);
  }

  async componentDidMount() {
    const { appState } = this.context;
    const selectedPage =
      appState.selectedResource === ResourceName.DONATION_EVENT
        ? appState.selectedPage
        : 0;

    var organization = this.props.location.state?.organization;
    if (!organization) {
      organization = await (await getOrganization(1)).json();
    }
    organization = organization || EmptyOrganization;
    this.setState(
      {
        organization: organization,
      },
      () => {
        // This code will be executed after the state has been updated
        this.setState({ isLoading: true });
        this.paginate({ selected: selectedPage });
      }
    );
  }

  async remove(id) {
    // Display a confirmation dialog before proceeding with the delete action
    const isConfirmed = window.confirm(
      'Are you sure you want to delete this entry?'
    );

    if (!isConfirmed) {
      // If the user cancels, exit the function
      return;
    }

    this.setState({ isLoading: true });
    await deleteDonationEvent(id).then((donationEventResponse) => {
      if (donationEventResponse.message == null) {
        let updatedDonationEvents = [...this.state.donationEvents].filter(
          (i) => i.id !== id
        );
        this.setState({
          donationEvents: updatedDonationEvents,
          isLoading: false,
        });
      } else {
        this.setState({ isLoading: false });
        //report error
      }
    });
  }

  async paginate({ selected }) {
    const { updateAppSelectedPage } = this.context;
    updateAppSelectedPage(ResourceName.DONATION_EVENT, selected);

    const organization = this.state.organization;
    this.setState({ currentPage: selected });

    await getDonationEventsByOrganizationId(organization?.id, selected)
      .then((response) => response.json())
      .then((responseBody) => {
        var respData = responseBody.data;
        this.setState((state) => {
          return {
            isLoading: false,
            organization,
            donationEvents: respData.page,
            currentPage: selected,
            totalPageCount: respData.totalPages,
            totalElementCount: respData.totalElements,
            pageOffset: respData.offset,
            pageSize: respData.pageSize,
          };
        });
        // this.setState({ isLoading: false });
      });
  }

  render() {
    const { t } = this.props;
    const translate = createTranslator(t);
    const { appState } = this.context;
    const language = appState.language;

    const {
      isLoading,
      organization,
      donationEvents,
      currentPage,
      totalElementCount,
      totalPageCount,
      pageSize,
      pageOffset,
    } = this.state;

    if (isLoading) {
      return <p>Loading...</p>;
    }

    const donationEventList = donationEvents.map((donationEvent) => {
      const startDate = donationEvent.startDate
        ? formatDateMonthYear(donationEvent.startDate, language)
        : '';
      const endDate = donationEvent.endDate
        ? formatDateMonthYear(donationEvent.endDate, language)
        : '';
      const eventStatus = endDate.length
        ? 'COMPLETED'
        : startDate.length
        ? 'IN PROGRESS'
        : 'NEW';
      return (
        <tr key={donationEvent.id}>
          {canEditContent() && <td>{donationEvent.id}</td>}
          <td style={{ whiteSpace: 'nowrap' }}>
            <Link
              to={`/donation-events/page/${donationEvent.id}`}
              state={{ organization, donationEvent }}
            >
              {getText(donationEvent.name, donationEvent.nameAm, language)}
            </Link>
          </td>
          <td style={{ minWidth: '400px' }}>
            {getText(
              donationEvent.description,
              donationEvent.descriptionAm,
              language
            )}
          </td>
          <td>{startDate}</td>
          <td>{endDate}</td>
          <td
            style={
              eventStatus === 'IN PROGRESS'
                ? { color: 'green', fontStyle: 'italic' }
                : {}
            }
          >
            {translate(eventStatus, CaseFormat.UPPERCASE)}
          </td>
          {/* <td>{donationEvent.status}</td> */}
          {canEditContent() && (
            <td>
              <ButtonGroup>
                <Button
                  size='sm'
                  color='primary'
                  tag={Link}
                  to={`/donation-events/${donationEvent.id}`}
                  state={{ organization, donationEvent }}
                >
                  {t('Edit')}
                </Button>
                <Button
                  size='sm'
                  color='danger'
                  onClick={() => this.remove(donationEvent.id)}
                >
                  {t('Delete')}
                </Button>
              </ButtonGroup>
            </td>
          )}
        </tr>
      );
    });

    return (
      <div>
        <Container fluid className='paginated-list-container'>
          <BackArrow />
          {canEditContent() && (
            <div>
              <Button
                color='success'
                tag={Link}
                to='/donation-events/new'
                state={{
                  organization: organization,
                  donationEvent: { ...EmptyDonationEvent },
                }}
              >
                {t('Add Donation Event')}
              </Button>
            </div>
          )}
          <br />
          <h3 className='dark-green'>
            {/* {getText(organization.name, organization.nameAm, language)}:{' '} */}
            {t('Donation Events')}
          </h3>
          <Table className='mt-4'>
            <thead>
              <tr>
                {canEditContent() && <th width='5%'>ID</th>}
                <th width='20%'>{t('Name')}</th>
                <th width='40%'>{t('Description')}</th>
                <th width='10%'>{t('Start Date')}</th>
                <th width='10%'>{t('End Date')}</th>
                <th width='10%'>{t('Status')}</th>
                {canEditContent() && <th width='20%'>{t('Actions')}</th>}
              </tr>
            </thead>
            <tbody>{donationEventList}</tbody>
          </Table>
          <PageFooter
            totalPageCount={totalPageCount}
            currentPage={currentPage}
            paginate={this.paginate}
          />
        </Container>
      </div>
    );
  }
}
const HOCDonationEventListPaginated = withRouter(
  withTranslation()(DonationEventListPaginated)
);
export default HOCDonationEventListPaginated;

import React, { useContext, useState, useEffect } from 'react';
import {
  Navbar,
  NavbarBrand,
  NavItem,
  NavLink,
  Nav,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import usaFlag from './images/usa_flag.jpg';
import armeniaFlag from './images/armenia_flag.jpg';
import { useTranslation } from 'react-i18next';
import { AppContext } from './AppContext.js';
import { ResourceName } from './Constant.js';
import { isAdmin } from './Constant.js';
import './styles/AppNavbar.css';
import { createTranslator, CaseFormat } from './utils/TranslationUtil.js';
import { extractBookUUID } from './utils/TextUtil.js';

const AppNavbar = () => {
  const { t } = useTranslation();
  const translate = createTranslator(t);
  const { appState, logout, updateAppSelectedPage, toggleLanguage } =
    useContext(AppContext);
  const language = appState.language;
  const [selectedOptions, setSelectedOptions] = useState({
    browse: 'editions',
    search: 'title',
  });
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  const minNavbarWidth = 1500; // Minimum width for full Navbar
  const [isMobile, setIsMobile] = useState(window.innerWidth < minNavbarWidth);
  const handleResize = () => {
    setIsMobile(window.innerWidth < minNavbarWidth);
  };

  useEffect(() => {
    // Check on initial render and on window resize
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleBrowseSelect = (option) => {
    updateAppSelectedPage(ResourceName.UNDEFINED, 0);
    setSelectedOptions((prev) => ({ ...prev, browse: option }));
    navigate(`/${option}`);
  };

  const handleSearchSelect = (option) => {
    setSearchQuery('');
    setSelectedOptions((prev) => ({ ...prev, search: option }));
  };

  const emptySearchPaths = {
    title: '/editions',
    work: '/works',
    author: '/authors',
    ISBN: '/editions',
    UUID: '/books',
    libraries: '/organizations',
    series: '/series',
    publishers: '/publishers',
  };

  const handleSearch = (event) => {
    if (event.key === 'Enter') {
      updateAppSelectedPage(ResourceName.UNDEFINED, 0);
      event.preventDefault();

      const searchPaths = {
        title: `/editions?keyword=${encodeURIComponent(searchQuery)}`,
        work: `/works?keyword=${encodeURIComponent(searchQuery)}`,
        author: `/authors?keyword=${encodeURIComponent(searchQuery)}`,
        ISBN: `/editions/search/${searchQuery}`,
        UUID: `/catalog/${extractBookUUID(searchQuery)}`,
        libraries: `/organizations?keyword=${encodeURIComponent(searchQuery)}`,
        series: `/series?keyword=${encodeURIComponent(searchQuery)}`,
        publishers: `/publishers?keyword=${encodeURIComponent(searchQuery)}`,
      };

      navigate(
        searchQuery.length
          ? searchPaths[selectedOptions.search]
          : emptySearchPaths[selectedOptions.search]
      );
    }
  };

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  const AboutUsLink = () => (
    <NavItem style={navItemStyle}>
      <NavLink tag={Link} to='/about-us'>
        {t('ABOUT US')}
      </NavLink>
    </NavItem>
  );

  const BrowseItem = () => (
    <NavItem style={{ ...navItemStyle, marginRight: '10px' }}>
      <BrowseDropdown
        selectedOption={selectedOptions.browse}
        onSelect={handleBrowseSelect}
      />
    </NavItem>
  );

  const LoginItem = () =>
    appState.username ? (
      <NavItem style={loginNavItemStyle}>
        <LoginDropdown username={appState.username} onLogout={handleLogout} />
      </NavItem>
    ) : (
      <NavItem style={navItemStyle}>
        <NavLink tag={Link} to='/login'>
          {t('LOG IN')}
        </NavLink>
      </NavItem>
    );

  const FlagToggle = () => (
    <NavItem style={flagNavItemStyle}>
      <img
        src={language === 'en' ? usaFlag : armeniaFlag}
        alt={language === 'en' ? 'English' : 'Armenian'}
        className='flag-icon'
        onClick={toggleLanguage}
        style={{ cursor: 'pointer', width: '30px', height: '20px' }}
      />
    </NavItem>
  );

  return (
    <Navbar color='dark' dark expand='md' className='navbar-style'>
      <NavbarBrand
        tag={Link}
        to='/'
        style={{ marginLeft: '10px', fontSize: isMobile ? '16px' : '1.25rem' }} // Default Bootstrap font size for NavbarBrand is 1.25rem
      >
        {t('ARMENIAN LIBRARY PROJECT')}
      </NavbarBrand>
      <Nav
        className='mr-auto d-flex flex-row align-items-center'
        navbar
        style={{ flexWrap: 'wrap' }}
      >
        <AboutUsLink />
        {!isMobile && <BrowseItem />}
        <Nav
          className='mr-auto d-flex flex-row align-items-center'
          navbar
          style={{
            flexWrap: 'nowrap',
            marginLeft: '10px',
            marginRight: '10px',
          }}
        >
          <NavItem style={searchNavItemStyle}>
            <SearchDropdown
              selectedOption={selectedOptions.search}
              onSelect={handleSearchSelect}
            />
          </NavItem>
          <NavItem style={{ ...searchNavItemStyle, flex: 1 }}>
            <input
              type='text'
              placeholder={t('Search...')}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyDown={handleSearch}
              onFocus={(e) => e.target.select()} // Automatically selects text on focus
              className='search-input'
              style={searchInputStyle}
            />
          </NavItem>
        </Nav>
      </Nav>

      <Nav
        className='mr-auto d-flex flex-row align-items-center'
        navbar
        style={{ flexWrap: 'wrap' }}
      >
        <LoginItem />
        <FlagToggle />
      </Nav>
    </Navbar>
  );
};

export default AppNavbar;

const BrowseDropdown = ({ selectedOption, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const translate = createTranslator(t);

  const toggle = () => setIsOpen(!isOpen);

  const handleSelect = (option) => {
    onSelect(option);
    setIsOpen(false);
  };

  // Filter options based on isAdmin status
  const menuOptions = isAdmin()
    ? [
        'editions',
        'works',
        'authors',
        'books',
        'organizations',
        'publishers',
        'series',
      ]
    : ['editions', 'authors', 'organizations'];

  return (
    <Dropdown isOpen={isOpen} toggle={toggle}>
      <DropdownToggle caret style={dropdownStyle}>
        {translate('browse', CaseFormat.UPPERCASE)}
      </DropdownToggle>
      <DropdownMenu>
        {menuOptions.map((option) => (
          <DropdownItem key={option} onClick={() => handleSelect(option)}>
            {t(option.charAt(0).toUpperCase() + option.slice(1))}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

const SearchDropdown = ({ selectedOption, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const translate = createTranslator(t);

  const toggle = () => setIsOpen(!isOpen);

  const handleSelect = (option) => {
    onSelect(option);
    setIsOpen(false);
  };

  // Filter options based on isAdmin status
  const menuOptions = isAdmin()
    ? [
        'title',
        'work',
        'author',
        'ISBN',
        'UUID',
        'libraries',
        'series',
        'publishers',
      ]
    : ['title', 'author', 'ISBN', 'UUID'];
  return (
    <Dropdown isOpen={isOpen} toggle={toggle}>
      <DropdownToggle caret style={dropdownStyle}>
        {translate(selectedOption, CaseFormat.UPPERCASE)}
      </DropdownToggle>
      <DropdownMenu>
        {menuOptions.map((option) => (
          <DropdownItem key={option} onClick={() => handleSelect(option)}>
            {t(option.charAt(0).toUpperCase() + option.slice(1))}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

const LoginDropdown = ({ username, onLogout }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const translate = createTranslator(t);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <Dropdown nav isOpen={isOpen} toggle={toggle}>
      <DropdownToggle nav caret>
        {username}
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={onLogout}>
          {translate('log out', CaseFormat.UPPERCASE)}
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

const searchNavItemStyle = { margin: '8px 0' };
// const searchNavItemStyle = { margin: '8px 0px 8x 10px' }; // margin-top, margin-right, margin-bottom, margin-left
const searchInputStyle = {
  height: '40px',
  boxSizing: 'border-box',
  borderColor: 'transparent',
  borderRadius: '0',
  width: '100%',
  minWidth: '200px',
};

const commonMargin = { margin: '8px 10px' };

const flagNavItemStyle = {
  ...commonMargin,
};

const navItemStyle = {
  ...commonMargin,
  width: '200px', // Specific width for this style
};

const loginNavItemStyle = {
  ...commonMargin,
  width: '250px', // Specific width for this style
};

const flagIconStyle = {
  cursor: 'pointer',
};

const dropdownStyle = {
  backgroundColor: 'transparent',
  color: '#fff',
  borderColor: 'dark',
  height: '40px',
  borderRadius: '0',
};

import React from 'react';
import ReactPaginate from 'react-paginate';
import { Link, useNavigate } from 'react-router-dom';
import { FormGroup, Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  formButtonStyle,
  formButtonIconStyle,
  containerStyle,
  containerStyleWithCursor,
  iconStyle,
} from '../InlineStyles.js';
import {
  faTimes,
  faTrashAlt,
  faCheck,
  faArrowLeft,
} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import {
  getOpenLibraryMediumCoverUrl,
  getOpenLibraryLargeCoverUrl,
} from '../OpenLibraryConverter.js';
import { Status, StatusOptions } from '../Constant.js';
import schoolImage from '../images/school.png';
import bookCoverFallbackImage from '../images/fallback_book_cover.png';
import { isMobile } from 'react-device-detect';
import { getEllipsizedText } from './TextUtil.js';
import { formatDate, formatDateMonthYear } from './DateTimeUtil.js';

export const hasCoverImage = (edition) => {
  if (edition?.openLibraryCoverId > 0 || edition?.externalCoverUrl) {
    return true;
  }
  return false;
};

export const BookCover = ({ edition }) => {
  const imageUrl1 = edition?.externalCoverUrl || '';
  const imageUrl2 =
    edition?.openLibraryCoverId > 0
      ? getOpenLibraryMediumCoverUrl(edition.openLibraryCoverId)
      : '';

  return hasCoverImage(edition) ? (
    <div>
      <img
        src={imageUrl1 || imageUrl2}
        alt={edition.title}
        className='edition-list-item-image'
        onError={(e) => {
          if (e.target.src === imageUrl1 && imageUrl2) {
            e.target.src = imageUrl2;
          } else {
            e.target.src = bookCoverFallbackImage;
          }
        }}
      />
    </div>
  ) : (
    <BookCoverFallback title={edition?.title} width='135px' height='185px' />
  );
};

export const SchoolImage = () => (
  <div>
    <img src={schoolImage} className='page-image' />
  </div>
);

export const BookCoverBig = ({ edition }) => {
  const imageUrl1 = edition?.externalCoverUrl || '';
  const imageUrl2 =
    edition?.openLibraryCoverId > 0
      ? getOpenLibraryLargeCoverUrl(edition.openLibraryCoverId)
      : '';

  // const fallbackImageUrl =
  //   'https://openlibrary.org/images/icons/avatar_book-lg.png';
  return hasCoverImage(edition) ? (
    <div>
      <img
        src={imageUrl1 || imageUrl2}
        alt={edition.title}
        className='edition-page-image'
        onError={(e) => {
          if (e.target.src === imageUrl1 && imageUrl2) {
            e.target.src = imageUrl2;
          } else {
            e.target.src = bookCoverFallbackImage;
          }
        }}
      />
    </div>
  ) : (
    <BookCoverFallback
      title={edition?.title}
      width='250px'
      height='auto'
      fontSize='1.2em'
      padding='20px'
      top='20px'
    />
  );
};

export const BookCoverFallback = ({
  title,
  width,
  height,
  fontSize = '1.0em',
  padding = '10px',
  top = '10px',
}) => {
  // Styles for the container and overlay text
  const styles = {
    container: {
      position: 'relative',
      width: width,
      height: height,
      overflow: 'hidden',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    },
    image: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    titleOverlay: {
      position: 'absolute',
      top: top,
      width: '95%',
      color: 'white',
      fontSize: fontSize,
      fontWeight: 'bold',
      textAlign: 'center',
      // backgroundColor: 'rgba(0, 0, 0, 0.6)',
      padding: padding,
    },
  };

  return (
    <div style={styles.container}>
      <img src={bookCoverFallbackImage} alt={title} style={styles.image} />
      <div style={styles.titleOverlay}>{getEllipsizedText(title, 50)}</div>
    </div>
  );
};

export const PageFooter = ({ totalPageCount, currentPage, paginate }) => {
  const { t } = useTranslation();
  if (totalPageCount <= 1) return null; // Don't render if there's only one page

  const commonProps = {
    onPageChange: paginate,
    pageCount: totalPageCount,
    previousLabel: t('Prev'),
    nextLabel: t('Next'),
    containerClassName: 'pagination',
    pageLinkClassName: 'page-number',
    previousLinkClassName: 'page-number',
    nextLinkClassName: 'page-number',
    activeLinkClassName: 'active',
    forcePage: currentPage,
  };

  return (
    <ReactPaginate
      {...commonProps}
      pageRangeDisplayed={isMobile ? 2 : 5} // Adjust page numbers for mobile vs desktop
      marginPagesDisplayed={isMobile ? 1 : 2} // Adjust margin pages for mobile vs desktop
    />
  );
};

export const FormTitle = ({ id, name, resourceName }) => {
  const { t } = useTranslation();
  const titlePref = id ? t(resourceName) : t('Add') + ' ' + t(resourceName);
  const title = name ? (
    <Pair label={titlePref} value={name} valueClass={'custom-text-blue'} />
  ) : (
    titlePref
  );
  return (
    <div>
      <h2>{title}</h2>
    </div>
  );
};

export const SubFormTitle = ({ name, resourceName }) => {
  const { t } = useTranslation();
  //const title = `${t(resourceName)}: ${name}`;
  const title = (
    <Pair
      label={t(resourceName)}
      value={name}
      valueClass={'custom-text-blue'}
    />
  );
  return (
    <div>
      <h3>{title}</h3>
    </div>
  );
};

export const Pair = ({ label, value, valueClass }) => {
  const { t } = useTranslation();
  return (
    <p>
      {t(label)}
      {t(': ')}
      <span className={valueClass || 'custom-green'}>{t(value)}</span>
    </p>
  );
};

export const PairLink = ({ label, link }) => {
  const { t } = useTranslation();
  return (
    <p>
      {t(label)}
      {t(': ')}
      {link}
    </p>
  );
};

export const ViewTitle = ({ name, resourceName, nameClass }) => {
  const { t } = useTranslation();

  const title = (
    <span>
      {t(resourceName)}
      {t(': ')}
      <span className={nameClass || 'custom-green'}>{name}</span>
    </span>
  );

  return (
    <div>
      <h3>{title}</h3>
    </div>
  );
};

export const ViewSubtitle = ({ name, resourceName, nameClass }) => {
  const { t } = useTranslation();
  const title = (
    <span>
      {t(resourceName)}
      {t(': ')}
      <span className={nameClass || 'custom-green'}>{name}</span>
    </span>
  );
  return (
    <div>
      <h5 style={{ fontStyle: 'italic' }}>{title}</h5>
    </div>
  );
};

export const ComboTitle = ({
  title,
  subtitle,
  seriesTitle,
  seriesItemNumber,
}) => {
  return (
    <div>
      <div>{title}</div>
      {subtitle && <div style={styles.subtitle}>{subtitle}</div>}
      {seriesTitle && (
        <div style={styles.series}>
          {seriesTitle} {seriesItemNumber > 0 && `#${seriesItemNumber}`}
        </div>
      )}
    </div>
  );
};

// Define styles
const styles = {
  subtitle: {
    fontStyle: 'normal',
    fontSize: '0.9rem',
    marginTop: '4px',
  },
  series: {
    fontStyle: 'italic',
    fontSize: '0.9rem',
    marginTop: '2px',
  },
};

export const StatusFormGroup = ({ status, handleChange }) => {
  return (
    <FormGroup style={{ width: '30%' }}>
      <Label htmlFor='status'>Status</Label>
      <select
        value={status}
        defaultValue={Status.UNDEFINED}
        onChange={handleChange}
        className='form-control'
        name='status'
        id='status'
      >
        {StatusOptions.map((option) => (
          <option value={option.value}>{option.label}</option>
        ))}
      </select>
    </FormGroup>
  );
};

export function getAuthorList(work) {
  var authorList = work.authors.map((author, i) => {
    return (
      <Link
        to={`/authors/page/${author.id}`}
        state={{ author }}
        className='custom-green'
      >
        {author.name}
      </Link>
    );
  });

  if (authorList.length > 1) {
    authorList = authorList.reduce((prev, curr) => [prev, ', ', curr]);
  }
  return authorList;
}

export const CloseButton = ({ onClick }) => {
  const navigate = useNavigate();
  return (
    <button type='button' onClick={onClick} style={formButtonStyle}>
      <FontAwesomeIcon icon={faTimes} style={formButtonIconStyle} />
    </button>
  );
};

export const DeleteButton = ({ onClick }) => {
  return (
    <button type='button' onClick={onClick} style={formButtonStyle}>
      <FontAwesomeIcon icon={faTrashAlt} style={formButtonIconStyle} />
    </button>
  );
};

export const SaveButtonOLD = ({ onClick }) => {
  return (
    <button type='submit' onClick={onClick} style={formButtonStyle}>
      <FontAwesomeIcon icon={faCheck} style={formButtonIconStyle} />
    </button>
  );
};

export const SaveButton = () => {
  return (
    <button type='submit' style={formButtonStyle}>
      <FontAwesomeIcon icon={faCheck} style={formButtonIconStyle} />
    </button>
  );
};

export const BackButton = ({ onClick }) => {
  return (
    <button type='button' onClick={onClick} style={formButtonStyle}>
      <FontAwesomeIcon icon={faArrowLeft} style={formButtonIconStyle} />
    </button>
  );
};

export const BackToCatalog = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <Link to='/catalog' style={{ textDecoration: 'none', color: 'black' }}>
      <div style={containerStyle}>
        <FontAwesomeIcon icon={faArrowLeft} style={iconStyle} />
        <span>{t('Catalog')}</span>
      </div>
    </Link>
  );
};

export const BackArrow2 = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <Link to='/' style={{ textDecoration: 'none', color: 'black' }}>
      <div style={containerStyle}>
        <FontAwesomeIcon icon={faArrowLeft} style={iconStyle} />
        <span>{t('Back')}</span>
      </div>
    </Link>
  );
};

export const BackArrow = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div onClick={handleGoBack} style={containerStyleWithCursor}>
      <FontAwesomeIcon icon={faArrowLeft} style={iconStyle} />
      <span>{t('Back')}</span>
    </div>
  );
};

export const ContainerWithCloseButton = ({ onClose, children }) => {
  return (
    <div className='close-button-container'>
      <button className='close-button' onClick={onClose}>
        X
      </button>
      {children}
    </div>
  );
};

export const ContainerWithDeleteButton = ({ onClose, children }) => {
  return (
    <div className='close-button-container'>
      <button className='close-button' onClick={onClose}>
        X
      </button>
      {children}
    </div>
  );
};

export function LastUpdatedDate({ dateTimeString }) {
  return (
    <div>
      <p>Last updated {formatDate(dateTimeString)}</p>
    </div>
  );
}

export function CreatedDate({ dateTimeString }) {
  const { t } = useTranslation();
  return (
    <div>
      <p>
        {t('Created')} {formatDate(dateTimeString)}
      </p>
    </div>
  );
}

export const DateRange = ({ startDate, endDate }) => {
  const hasEndDate = endDate && endDate.length > 0;
  return (
    <p>
      {startDate} {hasEndDate && `- ${endDate}`}
    </p>
  );
};

export const DateRangeFormatted = ({ startDate, endDate, language }) => {
  const hasEndDate = endDate && endDate.length > 0;
  const { t } = useTranslation();
  return (
    <p>
      {hasEndDate &&
        `${formatDateMonthYear(startDate, language)} - ${formatDateMonthYear(
          endDate,
          language
        )}`}
      {/* : `${t('Started')} ${formatDateMonthYear(startDate, language)}`} */}
    </p>
  );
};

import React, { Component } from 'react';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import {
  Button,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
} from 'reactstrap';
import {
  getShippingBox,
  postShippingBox,
  deleteShippingBox,
  searchPersonProfilesByKeyword,
  isValidApiResponse,
} from './OpenGradaranApi.js';
import {
  EmptyShippingBox,
  EmptyOrganization,
  EmptyDonationEvent,
  EmptyPersonProfile,
  ShippingStatus,
  ShippingStatusOptions,
} from './Constant.js';
import { backgroundStyle, inputDisableStyle } from './InlineStyles.js';
import {
  DeleteButton,
  CloseButton,
  SaveButton,
  StatusFormGroup,
} from './utils/CustomViewUtil.js';
import { withTranslation } from 'react-i18next';
import { getFullName } from './utils/TextUtil.js';

/* This is a higher order component that
 *  inject a special prop to our component.
 */
function withRouter(Component) {
  function ComponentWithRouter(props) {
    let params = useParams();
    let navigate = useNavigate();
    let location = useLocation();
    return (
      <Component
        {...props}
        params={params}
        navigate={navigate}
        location={location}
      />
    );
  }
  return ComponentWithRouter;
}

class ShippingBoxForm extends Component {
  constructor(props) {
    super(props);
    const { organization, donationEvent, shippingBox } = this.props;
    this.state = {
      shippingBox: shippingBox || EmptyShippingBox,
      organization: organization || EmptyOrganization,
      donationEvent: donationEvent || EmptyDonationEvent,
      sender: shippingBox?.sender || EmptyPersonProfile,
      senderSearchValue: '', // Value entered in the input box
      senderOptions: shippingBox?.sender ? [shippingBox.sender] : [],
      recipient: shippingBox?.recipient || EmptyPersonProfile,
      recipientSearchValue: '', // Value entered in the input box
      recipientOptions: shippingBox?.recipient ? [shippingBox.recipient] : [],
      isSenderDropdownVisible: false,
      isRecipientDropdownVisible: false,
      isLoading: false, // Loading state for API calls
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSenderOptionClick = this.handleSenderOptionClick.bind(this);
    this.handleSenderInputChange = this.handleSenderInputChange.bind(this);
    this.handleRecipientOptionClick =
      this.handleRecipientOptionClick.bind(this);
    this.handleRecipientInputChange =
      this.handleRecipientInputChange.bind(this);

    this.handleDelete = this.handleDelete.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    var organization = this.props.location.state?.organization;
    var donationEvent = this.props.location.state?.donationEvent;
    var shippingBox = this.props.location.state?.shippingBox;
    if (organization && donationEvent && shippingBox) {
      let sender = shippingBox.sender || EmptyPersonProfile;
      let recipient = shippingBox.recipient || EmptyPersonProfile;
      this.setState({
        organization:
          organization || shippingBox?.organization || EmptyOrganization,
        donationEvent:
          donationEvent || shippingBox?.donationEvent || EmptyDonationEvent,
        shippingBox: shippingBox || EmptyShippingBox,
        sender,
        senderSearchValue: getFullName(sender), // Value entered in the input box
        senderOptions: shippingBox.sender ? [shippingBox.sender] : [],
        recipient,
        recipientSearchValue: getFullName(recipient), // Value entered in the input box
        recipientOptions: shippingBox.recipient ? [shippingBox.recipient] : [],
        isSenderDropdownVisible: false,
        isRecipientDropdownVisible: false,
        isLoading: false, // Loading state for API calls
      });
    }
  }

  handleSenderOptionClick(selectedSender) {
    this.setState({
      sender: selectedSender,
      senderSearchValue: getFullName(selectedSender),
      isSenderDropdownVisible: false,
    });
  }

  async handleSenderInputChange(event) {
    event.stopPropagation();
    const value = event.target.value;
    this.setState({ senderSearchValue: value, isLoading: true });

    this.findAndSetSender(value);
    if (value.length > 0) {
      this.setState({ isLoading: false, isSenderDropdownVisible: true });
    } else {
      this.setState({ isLoading: false, isSenderDropdownVisible: false });
    }
  }

  async findAndSetSender(value = '') {
    const sender = this.state.sender;
    try {
      const optionsResponse = await (
        await searchPersonProfilesByKeyword(value)
      ).json();
      if (isValidApiResponse(optionsResponse.message)) {
        const options = optionsResponse.data.page;
        this.setState({
          senderOptions: [EmptyPersonProfile, ...options],
        });
      } else {
        // handle errors
        console.error('Error fetching data:', optionsResponse.message);
      }
    } catch (error) {
      // handle errors
      console.error('Error fetching data:', error);
    }
  }

  handleRecipientOptionClick(selectedRecipient) {
    this.setState({
      recipient: selectedRecipient,
      recipientSearchValue: getFullName(selectedRecipient),
      isRecipientDropdownVisible: false,
    });
  }

  async handleRecipientInputChange(event) {
    event.stopPropagation();
    const value = event.target.value;
    this.setState({ recipientSearchValue: value, isLoading: true });

    this.findAndSetRecipient(value);
    if (value.length > 0) {
      this.setState({ isLoading: false, isRecipientDropdownVisible: true });
    } else {
      this.setState({ isLoading: false, isRecipientDropdownVisible: false });
    }
  }

  async findAndSetRecipient(value = '') {
    const recipient = this.state.recipient;
    try {
      const optionsResponse = await (
        await searchPersonProfilesByKeyword(value)
      ).json();
      if (isValidApiResponse(optionsResponse.message)) {
        const options = optionsResponse.data.page;
        this.setState({
          recipientOptions: [EmptyPersonProfile, ...options],
        });
      } else {
        // handle errors
        console.error('Error fetching data:', optionsResponse.message);
      }
    } catch (error) {
      // handle errors
      console.error('Error fetching data:', error);
    }
  }

  handleKeyDown = (event) => {
    if (event.key == 'Enter') {
      event.preventDefault(); // Prevent the default behavior of the "Enter" key
    }
  };

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    let shippingBox = { ...this.state.shippingBox };
    shippingBox[name] = value;
    this.setState({ shippingBox });
  }

  async handleSubmit(event) {
    event.preventDefault();
    const { shippingBox, organization, donationEvent, sender, recipient } =
      this.state;

    shippingBox.organization = organization;
    shippingBox.donationEvent = donationEvent?.id ? donationEvent : null;
    shippingBox.sender = sender?.id ? sender : null;
    shippingBox.recipient = recipient?.id ? recipient : null;

    var shippingBoxResponse = await (await postShippingBox(shippingBox)).json();
    if (isValidApiResponse(shippingBoxResponse)) {
      this.props.navigate(
        `/donation-events/${donationEvent.id}/shipping-boxes`,
        {
          state: { organization, donationEvent },
        }
      );
    } else {
      //report error
    }
  }

  async handleDelete(event) {
    event.preventDefault();

    // Display a confirmation dialog before proceeding with the delete action
    const isConfirmed = window.confirm(
      'Are you sure you want to delete this shipping box?'
    );

    if (!isConfirmed) {
      // If the user cancels, exit the function
      return;
    }

    const { shippingBox, donationEvent, organization } = this.state;
    if (shippingBox.id) {
      try {
        const shippingBoxResponse = await deleteShippingBox(shippingBox.id);
        if (isValidApiResponse(shippingBoxResponse)) {
          this.props.navigate(
            `/donation-events/${donationEvent.id}/shipping-boxes`,
            {
              state: { organization, donationEvent },
            }
          );
        } else {
          // Report invalid API response
          console.error('Invalid API response');
        }
      } catch (error) {
        // Handle network or other errors
        console.error('Error deleting shipping box:', error);
      }
    } else {
      this.props.navigate(-1);
    }
  }

  handleClose(event) {
    event.preventDefault();
    this.props.navigate(-1);
  }

  render() {
    const { t } = this.props;
    const {
      organization,
      donationEvent,
      shippingBox,
      sender,
      senderSearchValue,
      senderOptions,
      recipient,
      recipientSearchValue,
      recipientOptions,
      isSenderDropdownVisible,
      isRecipientDropdownVisible,
      isLoading,
    } = this.state;

    if (!shippingBox) {
      return;
    }

    const organizationView = organization?.id ? (
      <p>
        Book Provider Organization:{' '}
        <Link
          to={`/organizations/page/${organization.id}`}
          state={{ organization }}
          className='custom-link-dark-blue'
        >
          {organization.name}
        </Link>
      </p>
    ) : null;

    const donationEventView = donationEvent?.id ? (
      <p>
        Donation Event:{' '}
        <Link
          to={`/donation-events/page/${donationEvent.id}`}
          state={{ donationEvent }}
          className='custom-link-dark-blue'
        >
          {donationEvent.name}
        </Link>
      </p>
    ) : null;
    const title = (
      <h2>{shippingBox.id ? 'Edit Shipping Box' : 'Add Shipping Box'}</h2>
    );

    return (
      <div>
        <div style={backgroundStyle}>
          <Container className='green-white'>
            <Row className='form-container'>
              {title}
              <Form onSubmit={this.handleSubmit}>
                {organizationView}
                {donationEventView}
                <Row>
                  <Col>
                    <FormGroup>
                      <Label htmlFor='id'>Shipping Box ID</Label>
                      <Input
                        type='text'
                        name='id'
                        id='id'
                        value={shippingBox.id}
                        onChange={this.handleChange}
                        onKeyDown={this.handleKeyDown}
                        autoComplete='name'
                        readOnly
                        style={{
                          background: '#f0f0f0',
                          border: 'none',
                          boxShadow: 'none',
                          cursor: 'not-allowed',
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label htmlFor='trackingNumber'>Tracking Number</Label>
                      <Input
                        type='text'
                        name='trackingNumber'
                        id='trackingNumber'
                        value={shippingBox.trackingNumber}
                        onChange={this.handleChange}
                        onKeyDown={this.handleKeyDown}
                        autoComplete='name'
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label htmlFor='tag'>Tag</Label>
                      <Input
                        type='text'
                        name='tag'
                        id='tag'
                        value={shippingBox.tag}
                        onChange={this.handleChange}
                        onKeyDown={this.handleKeyDown}
                        autoComplete='name'
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label htmlFor='sender'>Sender</Label>
                      <Input
                        type='text'
                        id='sender'
                        value={senderSearchValue}
                        onChange={this.handleSenderInputChange}
                        onKeyDown={this.handleKeyDown}
                        className='form-control'
                        placeholder='Search...'
                      />
                      {isSenderDropdownVisible && !isLoading && (
                        <div className='dropdown-menu show'>
                          {senderOptions.map((option) => (
                            <Button
                              key={option.id}
                              className='dropdown-item'
                              onClick={() =>
                                this.handleSenderOptionClick(option)
                              }
                            >
                              {getFullName(option)}
                            </Button>
                          ))}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label htmlFor='recipient'>Recipient</Label>
                      <Input
                        type='text'
                        id='recipient'
                        value={recipientSearchValue}
                        onChange={this.handleRecipientInputChange}
                        onKeyDown={this.handleKeyDown}
                        className='form-control'
                        placeholder='Search...'
                      />

                      {isRecipientDropdownVisible && !isLoading && (
                        <div className='dropdown-menu show'>
                          {recipientOptions.map((option) => (
                            <Button
                              key={option.id}
                              className='dropdown-item'
                              onClick={() =>
                                this.handleRecipientOptionClick(option)
                              }
                            >
                              {getFullName(option)}
                            </Button>
                          ))}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label htmlFor='shippingStatus'>Shipping Status</Label>
                      <select
                        value={shippingBox.shippingStatus}
                        defaultValue={ShippingStatus.UNDEFINED}
                        onChange={this.handleChange}
                        className='form-control'
                        name='shippingStatus'
                        id='shippingStatus'
                      >
                        {ShippingStatusOptions.map((option) => (
                          <option value={option.value}>{option.label}</option>
                        ))}
                      </select>
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup>
                  <Label htmlFor='notes'>Notes</Label>
                  <Input
                    type='textarea'
                    rows={5}
                    name='notes'
                    id='notes'
                    value={shippingBox.notes}
                    onChange={this.handleChange}
                    onKeyDown={this.handleKeyDown}
                    autoComplete='name'
                  />
                </FormGroup>
                <StatusFormGroup
                  status={shippingBox.status}
                  handleChange={this.handleChange}
                />
                <FormGroup>
                  <SaveButton />
                  <CloseButton onClick={this.handleClose} />
                  <DeleteButton onClick={this.handleDelete} />
                </FormGroup>
                {/* <FormGroup>
                <Button color='primary' type='submit'>
                  Save
                </Button>{' '}
                <Button
                  color='secondary'
                  tag={Link}
                  to={`/donation-events/${donationEvent.id}/shipping-boxes`}
                >
                  Cancel
                </Button>
              </FormGroup> */}
              </Form>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}
const HOCShippingBoxForm = withRouter(withTranslation()(ShippingBoxForm));
export default HOCShippingBoxForm;

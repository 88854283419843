import React from 'react';

export function getFullName(personProfile) {
  if (personProfile) {
    return `${personProfile?.firstName} ${personProfile?.lastName}`.trim();
  }
  return '';
}

// Function to truncate the title and add ellipsis if it's too long
export const getEllipsizedText = (text, maxLength) => {
  return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
};

export const renderWithDelimiter = (texts, delimiter) => {
  return texts
    .filter((text) => text) // Filter out null, undefined, or empty strings
    .map((text, index, array) => (
      <React.Fragment key={index}>
        {text} {index < array.length - 1 && delimiter}{' '}
        {/* Add the delimiter between items */}
      </React.Fragment>
    ));
};

export const doubleThirdDash = (uuid) => {
  // Split the UUID into parts by dashes
  const parts = uuid.split('-');

  // Check if the UUID has at least 4 parts
  if (parts.length >= 4) {
    // Double the third dash (the third part in the array)
    parts[3] = '--' + parts[3].slice(1); // Add another dash at the start of the third part
  }

  // Rejoin the parts back into a string and return it
  return parts.join('-');
};

export const getUuidWithLogDashes = (uuid) => uuid.replace(/-/g, '—');

export const splitUUID = (uuid) => {
  // Split the UUID into parts based on the dashes
  const parts = uuid.split('-');

  // Join the first three parts for the first half
  const firstPart = parts.slice(0, 3).join('—') + '—';

  // Join the remaining parts for the second half
  const secondPart = '—' + parts.slice(3).join('—');

  // Return the two parts as an object
  return { firstPart, secondPart };
};

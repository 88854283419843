import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Button,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
} from 'reactstrap';
import {
  getPersonProfile,
  postPersonProfile,
  deletePersonProfile,
  searchOrganizations,
  isValidApiResponse,
} from './OpenGradaranApi.js';
import {
  EmptyPersonProfile,
  EmptyOrganization,
  GradeLevel,
  GradeLevelOptions,
  Occupation,
  OccupationOptions,
} from './Constant.js';
import { backgroundStyle, inputDisableStyle } from './InlineStyles.js';
import {
  FormTitle,
  DeleteButton,
  CloseButton,
  SaveButton,
  StatusFormGroup,
} from './utils/CustomViewUtil.js';
import { useTranslation } from 'react-i18next';
import { getFullName } from './utils/TextUtil.js';

const PersonProfileForm = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [personProfile, setPersonProfile] = useState({ ...EmptyPersonProfile });
  const [organizationSearchValue, setOrganizationSearchValue] = useState('');
  const [organizationOptions, setOrganizationOptions] = useState([]);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchPersonProfile = async () => {
      if (id !== 'new') {
        const fetchedPersonProfile = await (await getPersonProfile(id)).json();
        setPersonProfile(fetchedPersonProfile);
        setOrganizationSearchValue(
          fetchedPersonProfile.organization?.name || ''
        );
      }
    };

    fetchPersonProfile();
  }, [id]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setPersonProfile((prevPersonProfile) => ({
      ...prevPersonProfile,
      [name]: value,
    }));
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent the default behavior of the "Enter" key
    }
  };

  const handleAlpParticipantCheckbox = (event) => {
    setPersonProfile((prevPersonProfile) => ({
      ...prevPersonProfile,
      ['alpParticipant']: event.target.checked,
    }));
  };

  const handleOrganizationOptionClick = (selectedOrganization) => {
    setPersonProfile((prevPersonProfile) => ({
      ...prevPersonProfile,
      ['organization']: selectedOrganization,
    }));
    setOrganizationSearchValue(selectedOrganization?.name || '');
    setIsDropdownVisible(false);
  };

  const handleOrganizationInputChange = async (event) => {
    event.stopPropagation();
    const value = event.target.value;
    setOrganizationSearchValue(value);
    setIsLoading(true);

    await findAndSetOrganization(value);
    if (value.length > 0) {
      setIsLoading(false);
      setIsDropdownVisible(true);
    } else {
      setIsLoading(false);
      setIsDropdownVisible(false);
    }
  };

  const findAndSetOrganization = async (value = '') => {
    try {
      const optionsResponse = await (await searchOrganizations(value)).json();
      if (isValidApiResponse(optionsResponse.message)) {
        const options = [EmptyOrganization, ...optionsResponse.data.page];
        setOrganizationOptions(options);
      } else {
        console.error('Error fetching data:', optionsResponse.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!personProfile.firstName && !personProfile.lastName) {
      //report error
      return;
    }
    if (!personProfile.organization?.id) {
      personProfile.organization = null;
    }
    const personProfileResponse = await (
      await postPersonProfile(personProfile)
    ).json();
    if (isValidApiResponse(personProfileResponse)) {
      navigate('/person-profiles');
    } else {
      // report error
    }
  };

  const handleClose = (event) => {
    event.preventDefault();
    navigate(-1);
  };

  const handleDelete = async (event) => {
    event.preventDefault();

    // Display a confirmation dialog before proceeding with the delete action
    const isConfirmed = window.confirm(
      'Are you sure you want to delete this person profile?'
    );

    if (!isConfirmed) {
      // If the user cancels, exit the function
      return;
    }

    if (personProfile.id) {
      try {
        const personProfileResponse = await deletePersonProfile(
          personProfile.id
        );
        if (isValidApiResponse(personProfileResponse)) {
          navigate('/person-profiles');
        } else {
          // Report invalid API response
          console.error('Invalid API response');
        }
      } catch (error) {
        // Handle network or other errors
        console.error('Error deleting person profile:', error);
      }
    } else {
      navigate(-1);
    }
  };

  return (
    <div>
      <div style={backgroundStyle}>
        <Container className='green-white'>
          <div className='form-container'>
            <FormTitle
              id={personProfile.id}
              name={getFullName(personProfile)}
              resourceName={'PersonProfile'}
            />
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <FormGroup>
                    <Label htmlFor='id'>ID</Label>
                    <Input
                      type='text'
                      name='id'
                      id='id'
                      value={personProfile.id}
                      onChange={handleChange}
                      autoComplete='name'
                      readOnly
                      style={inputDisableStyle}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label htmlFor='occupation'>{t('Occupation')}</Label>
                    <select
                      value={personProfile.occupation}
                      defaultValue={Occupation.UNDEFINED}
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                      className='form-control'
                      name='occupation'
                      id='occupation'
                    >
                      {OccupationOptions.map((option) => (
                        <option value={option.value}>{option.label}</option>
                      ))}
                    </select>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label htmlFor='gradeLevel'>{t('Grade')}</Label>
                    <select
                      value={personProfile.gradeLevel}
                      defaultValue={GradeLevel.UNDEFINED}
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                      className='form-control'
                      name='gradeLevel'
                      id='gradeLevel'
                    >
                      {GradeLevelOptions.map((option) => (
                        <option value={option.value}>{option.label}</option>
                      ))}
                    </select>
                  </FormGroup>
                </Col>
                <FormGroup>
                  <Label
                    htmlFor='alpParticipant'
                    style={{ marginRight: '20px' }}
                  >
                    {t('ALP Participant')}
                  </Label>
                  <Input
                    type='checkbox'
                    checked={personProfile.alpParticipant}
                    name='alpParticipant'
                    id='alpParticipant'
                    value={personProfile.alpParticipant}
                    onChange={handleAlpParticipantCheckbox}
                    style={{ transform: 'scale(2)' }}
                    // onKeyDown={this.handleKeyDown}
                  />
                </FormGroup>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label htmlFor='firstName'>{t('First Name')}</Label>
                    <Input
                      type='text'
                      name='firstName'
                      id='firstName'
                      value={personProfile.firstName}
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                      autoComplete='name'
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor='firstNameAm'>
                      {t('First Name')} / {t('in Armenian')}
                    </Label>
                    <Input
                      type='text'
                      name='firstNameAm'
                      id='firstNameAm'
                      value={personProfile.firstNameAm}
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                      autoComplete='name'
                    />
                  </FormGroup>
                </Col>

                <Col>
                  <FormGroup>
                    <Label htmlFor='lastName'>{t('Last Name')}</Label>
                    <Input
                      type='text'
                      name='lastName'
                      id='lastName'
                      value={personProfile.lastName}
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                      autoComplete='name'
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor='lastNameAm'>
                      {t('Last Name')} / {t('in Armenian')}
                    </Label>
                    <Input
                      type='text'
                      name='lastNameAm'
                      id='lastNameAm'
                      value={personProfile.lastNameAm}
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                      autoComplete='name'
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label htmlFor='organization'>{t('Organization')}</Label>
                    <Input
                      type='text'
                      value={organizationSearchValue}
                      onChange={handleOrganizationInputChange}
                      onKeyDown={handleKeyDown}
                      className='form-control'
                      placeholder={t('Search...')}
                    />
                    {isDropdownVisible && !isLoading && (
                      <div className='dropdown-menu show'>
                        {organizationOptions.map((option) => (
                          <Button
                            key={option.id}
                            className='dropdown-item'
                            onClick={() =>
                              handleOrganizationOptionClick(option)
                            }
                          >
                            {option.name}
                          </Button>
                        ))}
                      </div>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <StatusFormGroup
                status={personProfile.status}
                handleChange={handleChange}
              />
              <FormGroup>
                <SaveButton />
                <CloseButton onClick={handleClose} />
                <DeleteButton onClick={handleDelete} />
              </FormGroup>
            </Form>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default PersonProfileForm;

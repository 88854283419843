import React, { Component } from 'react';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import { Container } from 'reactstrap';
import { getPublisher } from './OpenGradaranApi.js';
import { EmptyPublisher, canEditContent } from './Constant.js';
import { backgroundStyle } from './InlineStyles.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { ViewTitle, BackButton } from './utils/CustomViewUtil.js';
import { withTranslation } from 'react-i18next';
import { AppContext } from './AppContext.js';

/* This is a higher order component that
 *  inject a special prop to our component.
 */
function withRouter(Component) {
  function ComponentWithRouter(props) {
    let params = useParams();
    let navigate = useNavigate();
    let location = useLocation();
    return (
      <Component
        {...props}
        params={params}
        navigate={navigate}
        location={location}
      />
    );
  }
  return ComponentWithRouter;
}

class PublisherPage extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);

    this.state = {
      publisher: { ...EmptyPublisher },
    };
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
  }

  async componentDidMount() {
    const id = this.props.params.id;
    var publisher = this.props.location.state?.publisher;
    if (!publisher && id) {
      const publisherResp = await (await getPublisher(id)).json();
      if (publisherResp.message == null) {
        publisher = publisherResp;
      } else {
        // handle error
      }
    }
    this.setState({ publisher: publisher || EmptyPublisher });
  }

  handleEditClick(event) {
    event.preventDefault();
    console.log('Icon clicked!');
    const { publisher } = this.state;
    this.props.navigate(`/publishers/${publisher.id}`, {
      state: { publisher },
    });
  }

  handleGoBack = () => {
    this.props.navigate(-1); // Use history.goBack() to navigate back
  };

  render() {
    const { t } = this.props;
    const { appState } = this.context;
    const { publisher } = this.state;

    return (
      <div>
        <div style={backgroundStyle}>
          <Container
            className='subview-container-blue'
            style={{ width: '50%' }}
          >
            <div
              style={{
                position: 'relative',
                padding: '30px',
              }}
            >
              <ViewTitle name={publisher.name} resourceName={'Publisher'} />
              <p>
                <Link
                  to={`/publishers/${publisher.id}/editions`}
                  state={{ publisher }}
                  className='custom-green'
                >
                  {t('Editions')}
                </Link>
              </p>
              <p>
                <BackButton onClick={this.handleGoBack} />
              </p>
              {/* Edit icon at the upper right corner */}
              {canEditContent() && (
                <div className='edit-pencil-container'>
                  <a href='' onClick={this.handleEditClick}>
                    <FontAwesomeIcon
                      icon={faPencilAlt}
                      className='edit-pencil'
                    />
                  </a>
                </div>
              )}
            </div>
          </Container>
        </div>
      </div>
    );
  }
}
const HOCPublisherPage = withRouter(withTranslation()(PublisherPage));
export default HOCPublisherPage;

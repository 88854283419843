import React, { Component } from 'react';
import { Button, ButtonGroup, Container, Table } from 'reactstrap';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  getDonationsByOrganizationId,
  getDonationsByDonationEventId,
  getDonationsByShippingBoxId,
  getDonations,
  getOrganization,
  deleteDonation,
} from './OpenGradaranApi.js';
import { PageFooter, BackArrow, BookCover } from './utils/CustomViewUtil.js';
import {
  EmptyOrganization,
  EmptyDonationEvent,
  ResourceName,
  EmptyShippingBox,
  canEditContent,
  getLabelByOptionValue,
  DonationStatusOptions,
  ShippingStatusOptions,
} from './Constant.js';
import { AppContext } from './AppContext.js';
import { withTranslation } from 'react-i18next';
import { getText } from './utils/TranslationUtil.js';
import { getFullName } from './utils/TextUtil.js';
import { createTranslator, CaseFormat } from './utils/TranslationUtil.js';

/* This is a higher order component that
 *  inject a special prop   to our component.
 */
function withRouter(Component) {
  function ComponentWithRouter(props) {
    let params = useParams();
    let navigate = useNavigate();
    let location = useLocation();
    return (
      <Component
        {...props}
        params={params}
        navigate={navigate}
        location={location}
      />
    );
  }
  return ComponentWithRouter;
}

class DonationListPaginated extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      organization: { ...EmptyOrganization },
      donationEvent: { ...EmptyDonationEvent },
      donations: [],
      currentPage: 0,
      totalPageCount: 0,
      totalElementCount: 0,
      pageOffset: 0,
      pageSize: 0,
      sortField: 'id',
    };
    this.remove = this.remove.bind(this);
    this.paginate = this.paginate.bind(this);
    this.handleSort = this.handleSort.bind(this);
  }

  async componentDidMount() {
    const { appState } = this.context;

    const selectedPage =
      appState.selectedResource === ResourceName.DONATION
        ? appState.selectedPage
        : 0;

    var donationEvent = this.props.location.state?.donationEvent;
    const shippingBox = this.props.location.state?.shippingBox;
    var organization;
    if (donationEvent?.id) {
      organization = donationEvent.organization;
    } else if (shippingBox?.id) {
      organization = shippingBox.organization;
      donationEvent = shippingBox.donationEvent;
    } else {
      organization = this.props.location.state?.organization;
      if (!organization) {
        organization = await (await getOrganization(1)).json();
      }
      organization = organization || EmptyOrganization;
    }
    if (organization || donationEvent || shippingBox) {
      this.setState(
        {
          organization: organization || EmptyOrganization,
          donationEvent: donationEvent || EmptyDonationEvent,
          shippingBox: shippingBox || EmptyShippingBox,
        },
        () => {
          // This code will be executed after the state has been updated
          this.setState({ isLoading: true });
          this.paginate({ selected: selectedPage });
        }
      );
    } else {
      this.setState({ isLoading: true });
      this.paginate({ selected: selectedPage });
    }
  }

  async remove(id) {
    // Display a confirmation dialog before proceeding with the delete action
    const isConfirmed = window.confirm(
      'Are you sure you want to delete this entry?'
    );

    if (!isConfirmed) {
      // If the user cancels, exit the function
      return;
    }

    this.setState({ isLoading: true });
    await deleteDonation(id).then((donationResponse) => {
      if (donationResponse.message == null) {
        let updatedDonations = [...this.state.donations].filter(
          (i) => i.id !== id
        );
        this.setState({
          donations: updatedDonations,
          isLoading: false,
        });
      } else {
        this.setState({ isLoading: false });
        //report error
      }
    });
  }

  async handleSort(field) {
    this.setState(
      { sortField: field, isLoading: true },
      () => this.paginate({ selected: 0 }) // Reset to the first page when sorting
    );
  }

  async paginate({ selected }) {
    const { updateAppSelectedPage } = this.context;
    updateAppSelectedPage(ResourceName.DONATION, selected);

    const { organization, donationEvent, shippingBox, sortField } = this.state;
    this.setState({ currentPage: selected });
    var donationPageResponse;
    if (shippingBox?.id) {
      donationPageResponse = await (
        await getDonationsByShippingBoxId(shippingBox?.id, selected, sortField)
      ).json();
    } else if (donationEvent?.id) {
      donationPageResponse = await (
        await getDonationsByDonationEventId(
          donationEvent?.id,
          selected,
          sortField
        )
      ).json();
    } else if (organization?.id) {
      donationPageResponse = await (
        await getDonationsByOrganizationId(
          organization?.id,
          selected,
          sortField
        )
      ).json();
    } else {
      donationPageResponse = await (
        await getDonations(selected, sortField)
      ).json();
    }
    const respData = donationPageResponse.data;

    if (respData && respData.message == null) {
      this.setState((state) => {
        return {
          isLoading: false,
          organization,
          donationEvent,
          donations: respData.page,
          currentPage: selected,
          totalPageCount: respData.totalPages,
          totalElementCount: respData.totalElements,
          pageOffset: respData.offset,
          pageSize: respData.pageSize,
        };
      });
    } else {
      //report error
    }
  }

  render() {
    const { t } = this.props;
    const { appState } = this.context;
    const language = appState.language;

    const translate = createTranslator(t);
    const {
      isLoading,
      organization, //book provider organization
      donationEvent,
      shippingBox,
      donations,
      currentPage,
      totalElementCount,
      totalPageCount,
      pageSize,
      pageOffset,
    } = this.state;

    if (isLoading) {
      return <p>Loading...</p>;
    }

    const bookProviderOrganizationName = getText(
      organization?.name,
      organization?.nameAm,
      language
    );

    const donationList = donations.map((donation) => {
      const donationStatus = getLabelByOptionValue(
        DonationStatusOptions,
        donation.donationStatus
      );
      const shippingStatus = getLabelByOptionValue(
        ShippingStatusOptions,
        donation.shippingStatus
      );
      const book = donation.book;
      const orgName = book.organization
        ? getText(book.organization.name, book.organization.nameAm, language)
        : '';
      return (
        <tr key={donation.id}>
          {/* <td>{donation.id}</td> */}
          <Link
            to={`/donations/page/${donation.id}`}
            state={{ organization, donation, book }}
            className='button-link-left'
          >
            {donation.id}
            {/* Donation #{donation.id} */}
          </Link>
          <td>
            <Link to={`/books/page/${book.id}`} state={{ book }}>
              <BookCover edition={book.edition} />
            </Link>
          </td>
          <td>
            {/* This is a link to the library where the book is.*/}
            <Link
              to={`/organizations/page/${book.organization?.id}`}
              state={{ organization: book.organization }}
              className='button-link-left'
            >
              {orgName}
            </Link>
          </td>
          <td>
            {/* This is a link to the library where the book is.*/}
            <Link
              to={`/donation-events/page/${donation.donationEvent?.id}`}
              state={{
                organization: donation.organization,
                donationEvent: donation.donationEvent,
              }}
              className='button-link-left'
            >
              {getText(
                donation.donationEvent?.name,
                donation.donationEvent?.nameAm,
                language
              )}
            </Link>
          </td>
          <td>{getFullName(donation.donor)}</td>
          {/* <td>{donation.source}</td> */}
          <td>{translate(donationStatus, CaseFormat.CAPITALIZED)}</td>
          {canEditContent() && (
            <td>{translate(shippingStatus, CaseFormat.CAPITALIZED)}</td>
          )}
          {/* <td>{donation.shippingBox?.id}</td> */}
          {/* <td>{donation.status}</td> */}
          {/* <td>{donation.notes}</td> */}
          {canEditContent() && (
            <td>
              <ButtonGroup>
                <Button
                  size='sm'
                  color='primary'
                  tag={Link}
                  to={`/donations/${donation.id}`}
                  state={{ organization, donation, book: donation.book }}
                >
                  {t('Edit')}
                </Button>
                <Button
                  size='sm'
                  color='danger'
                  onClick={() => this.remove(donation.id)}
                >
                  {t('Delete')}
                </Button>
              </ButtonGroup>
            </td>
          )}
        </tr>
      );
    });

    return (
      <div>
        <Container fluid className='paginated-list-container'>
          <BackArrow />
          <br />
          <h3 className='dark-green'>{t('Donations')}</h3>
          {donationEvent?.id && <h4>{donationEvent.name}</h4>}
          {shippingBox?.id && (
            <h4>
              {t('Shipping Box')}: {shippingBox.id}
            </h4>
          )}
          <Table className='mt-4'>
            <thead>
              <tr>
                <th
                  width='5%'
                  onClick={() => this.handleSort('id')}
                  style={{ cursor: 'pointer' }}
                >
                  ID
                </th>
                <th width='10%'>{t('Book')}</th>
                {/* <th width='13%'>{t('Edition')}</th> */}
                <th width='12%'>{t('Library')}</th>
                <th
                  width='10%'
                  onClick={() => this.handleSort('donationEventId')}
                  style={{ cursor: 'pointer' }}
                >
                  {t('Donation Event')}
                </th>
                <th
                  width='10%'
                  onClick={() => this.handleSort('donorId')}
                  style={{ cursor: 'pointer' }}
                >
                  {t('Donor')}
                </th>
                {/* <th width='10%'>{t('Source')}</th> */}
                <th
                  width='10%'
                  onClick={() => this.handleSort('donationStatus')}
                  style={{ cursor: 'pointer' }}
                >
                  {t('Donation Status')}
                </th>
                {canEditContent() && (
                  <th width='10%'>{t('Shipping Status')}</th>
                )}
                {/* <th width='10%'>{t('Status')}</th> */}
                {/* <th width='10%'>{t('Notes')}</th> */}
                {canEditContent() && <th width='15%'>{t('Actions')}</th>}
              </tr>
            </thead>
            <tbody>{donationList}</tbody>
          </Table>
          <PageFooter
            totalPageCount={totalPageCount}
            currentPage={currentPage}
            paginate={this.paginate}
          />
        </Container>
      </div>
    );
  }
}
const HOCDonationListPaginated = withRouter(
  withTranslation()(DonationListPaginated)
);
export default HOCDonationListPaginated;

import React, { Component } from 'react';
import { Button, ButtonGroup, Container, Table } from 'reactstrap';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { getPersonProfiles, deletePersonProfile } from './OpenGradaranApi.js';
import { PageFooter, BackArrow } from './utils/CustomViewUtil.js';
import {
  EmptyPersonProfile,
  ResourceName,
  canEditContent,
} from './Constant.js';
import { AppContext } from './AppContext.js';
import { linkStyleNoUnderline } from './InlineStyles.js';
import { withTranslation } from 'react-i18next';

/* This is a higher order component that
 *  inject a special prop   to our component.
 */
function withRouter(Component) {
  function ComponentWithRouter(props) {
    let params = useParams();
    let navigate = useNavigate();
    let location = useLocation();
    return (
      <Component
        {...props}
        params={params}
        navigate={navigate}
        location={location}
      />
    );
  }
  return ComponentWithRouter;
}

class PersonProfileListPaginated extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      personProfiles: [],
      currentPage: 0,
      totalPageCount: 0,
      totalElementCount: 0,
      pageOffset: 0,
      pageSize: 0,
    };
    this.remove = this.remove.bind(this);
    this.paginate = this.paginate.bind(this);
  }

  async componentDidMount() {
    const { appState } = this.context;
    const selectedPage =
      appState.selectedResource === ResourceName.PERSON_PROFILE
        ? appState.selectedPage
        : 0;

    this.setState({ isLoading: true });
    this.paginate({ selected: selectedPage });
  }

  componentDidUpdate(prevProps) {
    // if (this.props.location.search !== prevProps.location.search) {
    //   const queryParams = new URLSearchParams(this.props.location.search);
    //   const keyword = queryParams.get('keyword');
    //   this.setState(
    //     {
    //       keyword,
    //     },
    //     () => {
    //       // This code will be executed after the state has been updated
    //       this.setState({ isLoading: true });
    //       this.paginate({ selected: 0 });
    //     }
    //   );
    // }
  }

  async remove(id) {
    // Display a confirmation dialog before proceeding with the delete action
    const isConfirmed = window.confirm(
      'Are you sure you want to delete this entry?'
    );

    if (!isConfirmed) {
      // If the user cancels, exit the function
      return;
    }

    this.setState({ isLoading: true });
    await deletePersonProfile(id).then((personProfileResponse) => {
      if (personProfileResponse.message == null) {
        let updatedPersonProfiles = [...this.state.personProfiles].filter(
          (i) => i.id !== id
        );
        this.setState({
          personProfiles: updatedPersonProfiles,
          isLoading: false,
        });
      } else {
        this.setState({ isLoading: false });
        //report error
      }
    });
  }

  async paginate({ selected }) {
    const { updateAppSelectedPage } = this.context;
    updateAppSelectedPage(ResourceName.PERSON_PROFILE, selected);

    var personProfilePageResponse;
    this.setState({ currentPage: selected });
    personProfilePageResponse = await (
      await getPersonProfiles(selected)
    ).json();

    if (personProfilePageResponse.message == null) {
      const respData = personProfilePageResponse.data;
      this.setState((state) => {
        return {
          isLoading: false,
          personProfiles: respData.page,
          currentPage: selected,
          totalPageCount: respData.totalPages,
          totalElementCount: respData.totalElements,
          pageOffset: respData.offset,
          pageSize: respData.pageSize,
        };
      });
    }
  }

  render() {
    const { t } = this.props;
    const { appState } = this.context;
    const {
      isLoading,
      personProfiles,
      currentPage,
      totalElementCount,
      totalPageCount,
      pageSize,
      pageOffset,
    } = this.state;

    if (isLoading) {
      return <p>Loading...</p>;
    }

    const personProfileList = personProfiles.map((personProfile) => {
      return (
        <tr key={personProfile.id}>
          <td>{personProfile.id}</td>
          <td style={{ whiteSpace: 'nowrap' }}>
            <Link
              to={`/person-profiles/page/${personProfile.id}`}
              state={{ personProfile }}
              style={linkStyleNoUnderline}
            >
              {`${personProfile.firstName} ${personProfile.lastName}`}
            </Link>
          </td>
          <td>{personProfile.occupation}</td>
          <td>{personProfile.organization?.name}</td>
          <td>{personProfile.alpParticipant ? '✓' : ''}</td>
          <td>{personProfile.address?.city}</td>
          {/* <td>{personProfile.status}</td> */}
          {canEditContent() && (
            <td>
              <ButtonGroup>
                <Button
                  size='sm'
                  color='primary'
                  tag={Link}
                  to={`/person-profiles/${personProfile.id}`}
                  state={{ personProfile }}
                >
                  {t('Edit')}
                </Button>
                <Button
                  size='sm'
                  color='danger'
                  onClick={() => this.remove(personProfile.id)}
                >
                  {t('Delete')}
                </Button>
              </ButtonGroup>
            </td>
          )}
        </tr>
      );
    });

    return (
      <div>
        <Container fluid className='paginated-list-container'>
          <BackArrow />
          {canEditContent() && (
            <div>
              <Button
                color='success'
                tag={Link}
                to='/person-profiles/new'
                state={{ personProfile: { ...EmptyPersonProfile } }}
              >
                Add PersonProfile
              </Button>
            </div>
          )}
          <br />
          <h3>PersonProfiles</h3>
          <Table className='mt-4'>
            <thead>
              <tr>
                <th width='10%'>{t('ID')}</th>
                <th width='15%'>{t('Name')}</th>
                <th width='10%'>{t('Occupation')}</th>
                <th width='15%'>{t('Organization')}</th>
                <th width='10%'>{t('ALP Participant')}</th>
                <th width='10%'>{t('Address')}</th>
                {/* <th width='10%'>{t('Status')}</th> */}
                {canEditContent() && <th width='20%'>{t('Actions')}</th>}
              </tr>
            </thead>
            <tbody>{personProfileList}</tbody>
          </Table>
          <PageFooter
            totalPageCount={totalPageCount}
            currentPage={currentPage}
            paginate={this.paginate}
          />
        </Container>
      </div>
    );
  }
}
const HOCPersonProfileListPaginated = withRouter(
  withTranslation()(PersonProfileListPaginated)
);
export default HOCPersonProfileListPaginated;

import React, { Component } from 'react';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import { Button, Container } from 'reactstrap';
import { EmptyWork, EmptyAuthor, canEditContent } from './Constant.js';
import { backgroundStyle } from './InlineStyles.js';
import { getWork } from './OpenGradaranApi.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import {
  ViewTitle,
  BackButton,
  getAuthorList,
  Pair,
} from './utils/CustomViewUtil.js';
import { withTranslation } from 'react-i18next';
import openlibrary from './images/openlibrary-logo-tighter.svg';
import { AppContext } from './AppContext.js';
import { createTranslator, CaseFormat } from './utils/TranslationUtil.js';

/* This is a higher order component that
 *  inject a special prop to our component.
 */
function withRouter(Component) {
  function ComponentWithRouter(props) {
    let params = useParams();
    let navigate = useNavigate();
    let location = useLocation();
    return (
      <Component
        {...props}
        params={params}
        navigate={navigate}
        location={location}
      />
    );
  }
  return ComponentWithRouter;
}

class WorkPage extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);

    this.state = {
      work: { ...EmptyWork },
    };
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
  }

  async componentDidMount() {
    const id = this.props.params.id;
    var work = this.props.location.state?.work;
    if (!work && id) {
      const workResp = await (await getWork(id)).json();
      if (workResp.message == null) {
        work = workResp;
      } else {
        // handle error
      }
    }
    this.setState({ work: work || EmptyWork });
  }

  handleEditClick(event) {
    event.preventDefault();
    console.log('Icon clicked!');
    const { work } = this.state;
    this.props.navigate(`/works/${work.id}`, { state: { work } });
  }

  handleGoBack = () => {
    this.props.navigate(-1); // Use history.goBack() to navigate back
  };

  render() {
    const { t } = this.props;
    const translate = createTranslator(t);
    const { appState } = this.context;
    const { work } = this.state;
    var authorList = getAuthorList(work);

    return (
      <div>
        <div style={backgroundStyle}>
          <Container className='light-blue-white'>
            <div
              style={{
                position: 'relative',
                padding: '30px',
              }}
            >
              <ViewTitle name={work.title} resourceName={'Work'} />
              <h2>{work.subtitle}</h2>
              <div style={{ position: 'relative' }}>
                <p>{work.description}</p>
              </div>
              <Pair
                label={'Original Language'}
                value={translate(work.originalLanguage, CaseFormat.CAPITALIZED)}
              />
              {work.series && (
                <Pair label={'Series'} value={work.series.title} />
              )}
              {work.seriesItemNumber > 0 && (
                <Pair label={'Book #'} value={work.seriesItemNumber} />
              )}
              {authorList && authorList.length > 0 && <p>by {authorList}</p>}
              {canEditContent() && (
                <p>
                  <Button
                    color='primary'
                    tag={Link}
                    to='/authors/new'
                    state={{ work, author: { ...EmptyAuthor } }}
                  >
                    {t('Add Author')}
                  </Button>
                </p>
              )}
              <p>
                <Link
                  to={`/works/${work.id}/editions`}
                  state={{ work }}
                  className='custom-green'
                >
                  {t('Editions')}
                </Link>
              </p>
              {work.openLibraryId && (
                <p>
                  <Link
                    to={`https://openlibrary.org/${work.openLibraryId}`}
                    className='custom-link-green'
                  >
                    <img
                      src={openlibrary}
                      alt='Open Library'
                      className='external-logo'
                    />
                  </Link>
                </p>
              )}
              <p>
                <BackButton onClick={this.handleGoBack} />
              </p>
              {/* Edit icon at the upper right corner */}
              {canEditContent() && (
                <div className='edit-pencil-container'>
                  <a href='' onClick={this.handleEditClick}>
                    <FontAwesomeIcon
                      icon={faPencilAlt}
                      className='edit-pencil'
                    />
                  </a>
                </div>
              )}
            </div>
          </Container>
        </div>
      </div>
    );
  }
}

const HOCWorkPage = withRouter(withTranslation()(WorkPage));
export default HOCWorkPage;

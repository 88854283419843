import React, { Component } from 'react';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { backgroundStyle } from './InlineStyles.js';
import { withTranslation } from 'react-i18next';
import { AppContext } from './AppContext.js';
import { getDonationEventsByOrganizationId } from './OpenGradaranApi.js';
import {
  getText,
  createTranslator,
  CaseFormat,
} from './utils/TranslationUtil.js';

/* This is a higher order component that
 *  inject a special prop to our component.
 */
function withRouter(Component) {
  function ComponentWithRouter(props) {
    let params = useParams();
    let navigate = useNavigate();
    let location = useLocation();
    return (
      <Component
        {...props}
        params={params}
        navigate={navigate}
        location={location}
      />
    );
  }
  return ComponentWithRouter;
}

class AboutUsPage extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      donationEvents: [],
    };

    this.handleGoBack = this.handleGoBack.bind(this);
  }

  async componentDidMount() {
    await getDonationEventsByOrganizationId(1)
      .then((response) => response.json())
      .then((responseBody) => {
        var respData = responseBody.data;
        this.setState((state) => {
          return {
            donationEvents: respData.page,
          };
        });
        // this.setState({ isLoading: false });
      });
  }

  handleGoBack = () => {
    this.props.navigate(-1);
  };

  render() {
    const { t } = this.props;
    const { appState } = this.context;
    const language = appState.language;
    const translate = createTranslator(t);
    const { donationEvents } = this.state;

    return (
      <div>
        <div style={backgroundStyle}>
          <Container className='subview-container-blue-semitransparent'>
            <div
              style={{
                position: 'relative',
                padding: '20px',
              }}
            >
              <h2>{t('Armenian Library Project')}</h2>

              <p>
                {getText(
                  'Our goal is to provide extracurriculum reading material to stimulate English readers in regional schools of the Republic of Armenia.',
                  'Մեր նպատակն է տրամադրել արտադասարանական ընթերցանության նյութեր՝   խթանելու անգլերեն (լեզվի) ընթերցողներին Հայաստանի Հանրապետության մարզային դպրոցներում։',
                  language
                )}
              </p>
              <Row style={{ marginBottom: '30px' }}>
                <Col>
                  <div>
                    <Link
                      to={'/organizations/1/donations'}
                      className='custom-green-bold'
                    >
                      <span>1900+</span>
                    </Link>{' '}
                    {getText('DONATED BOOKS', 'ՆՎԻՐՎԱԾ ԳՐՔԵՐ', language)}
                  </div>
                </Col>
                <Col>
                  <div>
                    <Link to={'/organizations'} className='custom-green-bold'>
                      <span>40+</span>
                    </Link>{' '}
                    {getText(
                      'SCHOOLS & LIBRARIES',
                      'ԴՊՐՈՑՆԵՐ ԵՎ ԳՐԱԴԱՐԱՆՆԵՐ',
                      language
                    )}
                  </div>
                </Col>
              </Row>
              <p>
                {getText(
                  'In 2022-23 our program has been reviewed and agreed upon by the Ministry of Education, Science, Culture and Sport of the Republic of Armenia.',
                  '2022-23թթ. մեր ծրագիրը քննարկվել և համաձայնեցվել է ՀՀ կրթության, գիտության, մշակույթի և սպորտի նախարարության (ՀՀ ԿԳՄՍՆ) կողմից։',
                  language
                )}
              </p>

              <p>
                {getText(
                  'As a part of the 2022-23 pilot, we established a process of cataloging those books, labeling them with unique QR codes, pointing to our utility database, with the goal of tracking utility of each book based on teachers feedback.',
                  '2022-23թթ. պիլոտային ծրագրի շրջանակներում մենք ստեղծեցինք գրքերի կատալոգավորման գործընթաց՝ դրանք պիտակավորելով եզակի QR կոդերով, որոնք հղում են պարունակում մեր տվյալների բազային՝ նպատակ ունենալով հետևել յուրաքանչյուր գրքի օգտագործմանը՝ հիմնվելով ուսուցիչների արձագանքների վրա։',
                  language
                )}
              </p>
              <p>
                {getText(
                  'We would like to express our endless gratitude to individuals and organizations who make possible this project:',
                  'Մենք ցանկանում ենք հայտնել մեր անսահման երախտագիտությունը այն անհատներին և կազմակերպություններին, ովքեր հնարավոր են դարձնում այս ծրագիրը․',
                  language
                )}
              </p>
              <ul>
                <li>
                  {getText(
                    'Our generous donors in the USA, both individuals and organizations.',
                    'Մեր առատաձեռն դոնորներին ԱՄՆ-ում՝ թե՛ անհատներին, թե՛ կազմակերպություններին:',
                    language
                  )}
                </li>
                <li>
                  {getText(
                    'Our heroical volunteers providing logistics support both in USA and in Armenia.',
                    'Մեր հերոսական կամավորներին, ովքեր տրամադրում են լոգիստիկ աջակցություն թե՛ ԱՄՆ-ում, թե՛ Հայաստանում:',
                    language
                  )}
                </li>
                <li>
                  {getText(
                    'Our devoted partners in Armenia: teachers, librarians, educators.',
                    'Մեր նվիրված գործընկերներին Հայաստանում՝ ուսուցիչներին, գրադարանավարներին, մանկավարժներին:',
                    language
                  )}
                </li>
              </ul>
              <p>
                {getText(
                  'We would like to acknowledge and thank the individuals and organizations in US and in Armenia that support, advise, and encourage this project, including but not limited to: St. Andrew Armenian Church (Cupertino, CA), Zartnir Foundation and European Party of Armenia.',
                  'Մենք ցանկանում ենք երախտագիտություն հայտնել ԱՄՆ-ում և Հայաստանում գործող անհատներին և կազմակերպություններին, որոնք աջակցում, խորհրդատվություն և քաջալերում են այս ծրագիրը, ներառյալ, բայց չսահմանափակվելով՝ Կուպերտինո քաղաքի Սուրբ Անդրեասի Հայկական Եկեղեցին,  Զարթնիր Հիմնադրամը, Հայաստանի Եվրոպական Կուսակցությունը։',
                  language
                )}
              </p>
              <h4>
                <Link
                  to='/donation-events/page/3'
                  state={{ donationEvent: donationEvents[2] }}
                  className='custom-green'
                >
                  {getText(
                    'The 2023-2024 Campaign (the 2nd year)',
                    '2023-2024թթ. արշավը (2-րդ տարի)',
                    language
                  )}
                </Link>
              </h4>
              <p>
                <p>
                  {getText(
                    'The 2023-24 campaign phases:',
                    '2023-24թթ. արշավի փուլերը․',
                    language
                  )}
                </p>
                <ul>
                  <li>
                    {t('Donation campaign')}{' '}
                    <span class='custom-green'>
                      [{translate('completed', CaseFormat.UPPERCASE)}]
                    </span>
                  </li>
                  <li>
                    {t('Book cataloging')}{' '}
                    <span class='custom-green'>
                      [{translate('completed', CaseFormat.UPPERCASE)}]
                    </span>
                  </li>
                  <li>
                    {t('Shipping to Armenia')}{' '}
                    <span class='custom-green'>
                      [{translate('completed', CaseFormat.UPPERCASE)}]
                    </span>
                  </li>
                  <li>
                    {t('Delivery to schools')}{' '}
                    <span class='custom-green'>
                      [{translate('completed', CaseFormat.UPPERCASE)}]
                    </span>
                  </li>
                  <li>
                    {t('Collecting feedback')}{' '}
                    <span class='custom-green'>
                      [{translate('in progress', CaseFormat.UPPERCASE)}]
                    </span>
                  </li>
                </ul>

                <p>
                  {getText(
                    'We collected and distributed',
                    'Մենք հավաքել և բաշխել ենք ',
                    language
                  )}{' '}
                  <Link
                    to='/donation-events/3/donations'
                    state={{ donationEvent: donationEvents[2] }}
                    className='custom-green-bold'
                  >
                    <span>822</span>
                  </Link>{' '}
                  {getText(
                    'English reading books to 27 schools and libraries:',
                    'անգլերեն ընթերցանության գրքեր 27 դպրոցների և գրադարանների․',
                    language
                  )}
                </p>
                <ul>
                  <li>
                    {getText(
                      '3 in Ararat marz',
                      '3-ը Արարատի մարզում',
                      language
                    )}
                  </li>
                  <li>
                    {getText(
                      '2 in Armavir marz',
                      '2-ը Արմավիրի մարզում',
                      language
                    )}
                  </li>
                  <li>
                    {getText(
                      '2 in Gegharkunik marz',
                      '2-ը Գեղարքունիքի մարզում',
                      language
                    )}
                  </li>
                  <li>
                    {getText(
                      '4 in Kotayk marz',
                      '4-ը Կոտայքի մարզում',
                      language
                    )}
                  </li>
                  <li>
                    {getText('7 in Lori marz', '7-ը Լոռու մարզում', language)}
                  </li>
                  <li>
                    {getText(
                      '1 in Shirak marz',
                      '1-ը Շիրակի մարզում',
                      language
                    )}
                  </li>
                  <li>
                    {getText(
                      '2 in Syunik marz',
                      '2-ը Սյունիքի մարզում',
                      language
                    )}
                  </li>
                  <li>
                    {getText(
                      '1 in Tavush marz',
                      '1-ը Տավուշի մարզում',
                      language
                    )}
                  </li>
                  <li>{getText('5 in Yerevan', '5-ը Երևանում', language)}</li>
                </ul>
              </p>
              <h4>
                <Link
                  to='/donation-events/page/2'
                  state={{ donationEvent: donationEvents[1] }}
                  className='custom-green'
                >
                  {getText(
                    'The Winter 2023 Science Book Donations',
                    '2023թ. ձմեռային գիտական գրքերի նվիրատվություն ',
                    language
                  )}
                </Link>
              </h4>
              <p>
                {getText('A total of', 'Ընդհանուր', language)}{' '}
                <Link
                  to='/donation-events/2/donations'
                  state={{ donationEvent: donationEvents[1] }}
                  className='custom-green-bold'
                >
                  <span>428</span>
                </Link>{' '}
                {getText(
                  'English science books were received and distributed to the following libraries:',
                  'անգլերեն գիտական գրքեր են ստացվել և բաշխվել հետևյալ գրադարաններին․',
                  language
                )}{' '}
              </p>

              <ul>
                <li>
                  {getText(
                    'Khnko Aper Child Library in Yerevan',
                    'Խնկո Ապեր մանկական գրադարան Երևանում',
                    language
                  )}
                </li>
                <li>
                  {getText(
                    '3 schools in Tavush marz',
                    '3 դպրոց Տավուշի մարզում',
                    language
                  )}
                </li>
                <li>
                  {getText(
                    'American Library and Training Center in Ijevan',
                    'Ամերիկյան գրադարան և ուսումնական կենտրոն Իջևանում',
                    language
                  )}
                </li>
                <li>{getText('Goris Library', 'Գորիսի գրադարան', language)}</li>
                <li>
                  {getText(
                    'Khnko Aper Library',
                    'Խնկո Ապեր գրադարան',
                    language
                  )}
                </li>
              </ul>
              <h4>
                <Link
                  to='/donation-events/page/1'
                  state={{ donationEvent: donationEvents[0] }}
                  className='custom-green'
                >
                  {getText(
                    'The 2022-2023 Pilot Program (the 1st year)',
                    '2022-2023թթ․ պիլոտային ծրագիր (1-ին տարի)',
                    language
                  )}
                </Link>
              </h4>
              <p>
                {getText(
                  'The goal of our 2022-23 pilot has been to validate the approach: to test the assumptions, to establish relations with donors (individuals and organizations in the US) and with the recipients (schools and libraries in Armenia), to refine the donation and shipping logistics, and to estimate our expenses.',
                  '2022-23թթ. պիլոտային ծրագրի նպատակն էր վավերացնել մոտեցումը․ ստուգել ենթադրությունները, հաստատել կապեր դոնորների (անհատներ և կազմակերպություններ ԱՄՆ-ում) և ստացողների (դպրոցներ և գրադարաններ Հայաստանում) հետ, կատարելագործել նվիրատվության և առաքման լոգիստիկան և գնահատել մեր ծախսերը։',
                  language
                )}
              </p>

              <p>
                {getText(
                  'The 2022-23 pilot phases:',
                  '2022-23թթ. պիլոտային ծրագրի փուլերը․',
                  language
                )}
              </p>
              <ul>
                <li>
                  {t('Statement of interest')}{' '}
                  <span class='custom-green'>
                    [{translate('completed', CaseFormat.UPPERCASE)}]
                  </span>
                </li>
                <li>
                  {t('Donation campaign')}{' '}
                  <span class='custom-green'>
                    [{translate('completed', CaseFormat.UPPERCASE)}]
                  </span>
                </li>
                <li>
                  {t('Book cataloging')}{' '}
                  <span class='custom-green'>
                    [{translate('completed', CaseFormat.UPPERCASE)}]
                  </span>
                </li>
                <li>
                  {t('Shipping to Armenia')}{' '}
                  <span class='custom-green'>
                    [{translate('completed', CaseFormat.UPPERCASE)}]
                  </span>
                </li>
                <li>
                  {t('Delivery to schools')}{' '}
                  <span class='custom-green'>
                    [{translate('completed', CaseFormat.UPPERCASE)}]
                  </span>
                </li>
                <li>
                  {t('Collecting feedback')}{' '}
                  <span class='custom-green'>
                    [{translate('completed', CaseFormat.UPPERCASE)}]
                  </span>
                </li>
              </ul>
              <p>
                {getText(
                  'We collected and distributed',
                  'Մենք հավաքել և բաշխել ենք ',
                  language
                )}{' '}
                <Link
                  to='/donation-events/1/donations'
                  state={{ donationEvent: donationEvents[0] }}
                  className='custom-green-bold'
                >
                  <span>627</span>
                </Link>{' '}
                {getText(
                  ' English reading books to 18 schools in Armenia willing to integrate the books in extracurricular programs:',
                  'անգլերեն ընթերցանության գրքեր Հայաստանի 18 դպրոցների, որոնք ցանկանում էին գրքերը ներառել արտադասարանական ծրագրերում․',
                  language
                )}
              </p>

              <ul>
                <li>
                  {getText('2 in Ararat marz', '2-ը Արարատի մարզում', language)}
                </li>
                <li>
                  {getText(
                    '2 in Armavir marz',
                    '2-ը Արմավիրի մարզում',
                    language
                  )}
                </li>
                <li>
                  {getText('3 in Kotayk marz', '3-ը Կոտայքի մարզում', language)}
                </li>
                <li>
                  {getText('5 in Lori marz', '5-ը Լոռու մարզում', language)}
                </li>
                <li>
                  {getText(
                    '1 in Syunik marz',
                    '1-ը Սյունիքի մարզում',
                    language
                  )}
                </li>
                <li>
                  {getText('1 in Tavush marz', '1-ը Տավուշի մարզում', language)}
                </li>
                <li>
                  {getText(
                    '2 in Vayots Dzor marz',
                    '2-ը Վայոց Ձորի մարզում',
                    language
                  )}
                </li>
                <li>{getText('2 in Yerevan', '2-ը Երևանում', language)}</li>
              </ul>
            </div>
            {/* <p>
              <BackButton onClick={this.handleGoBack} />
            </p> */}
          </Container>
        </div>
      </div>
    );
  }
}
const HOCAboutUsPage = withRouter(withTranslation()(AboutUsPage));
export default HOCAboutUsPage;

import React from 'react';
import { QRCodeCanvas } from 'qrcode.react';
import { doubleThirdDash, getUuidWithLogDashes, splitUUID } from './TextUtil';

const BookQRCode = ({ bookUUID }) => {
  const qrValue = `https://armenianlibraryproject.org/catalog/${bookUUID}`;
  const qrCodeSize = 200; // Size of the QR code itself
  const textRadius = qrCodeSize * 0.55; // Slightly larger than QR code to render text outside
  const padding = 15; // Fixed padding for consistent spacing
  const svgSize = qrCodeSize + 2 * (textRadius + padding); // Total SVG size
  const alpText = 'ArmenianLibraryProject.org';
  const uuidText = getUuidWithLogDashes(bookUUID.toUpperCase());
  const { firstPart, secondPart } = splitUUID(bookUUID.toUpperCase());

  return (
    <div
      style={{
        position: 'relative',
        width: svgSize,
        height: svgSize,
      }}
    >
      {/* QR Code */}
      <QRCodeCanvas
        value={qrValue}
        size={qrCodeSize}
        style={{
          position: 'absolute',
          top: (svgSize - qrCodeSize) / 2, // Center the QR code
          left: (svgSize - qrCodeSize) / 2,
        }}
      />

      {/* Square Path for Text Around the QR Code */}
      <svg
        width={svgSize}
        height={svgSize}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          pointerEvents: 'none',
        }}
      >
        <defs>
          {/* Path for alpText (Clockwise) */}
          <path
            id='alpPath'
            d={`M ${svgSize / 2 - textRadius - padding},${
              svgSize / 2 - textRadius - padding
            }
              L ${svgSize / 2 + textRadius + padding},${
              svgSize / 2 - textRadius - padding
            }
              L ${svgSize / 2 + textRadius + padding},${
              svgSize / 2 + textRadius + padding
            }
              L ${svgSize / 2 - textRadius - padding},${
              svgSize / 2 + textRadius + padding
            }
              Z`}
          />

          {/* Path for bookUUID (Counterclockwise) */}
          <path
            id='uuidPath'
            d={`M ${svgSize / 2 - textRadius - padding},${
              svgSize / 2 - textRadius - padding
            }
              L ${svgSize / 2 - textRadius - padding},${
              svgSize / 2 + textRadius + padding
            }
              L ${svgSize / 2 + textRadius + padding},${
              svgSize / 2 + textRadius + padding
            }
              L ${svgSize / 2 + textRadius + padding},${
              svgSize / 2 - textRadius - padding
            }
              Z`}
          />
        </defs>

        {/* Text following the clockwise square path */}
        <text fill='black' fontSize='16' style={{ letterSpacing: '1px' }}>
          <textPath href='#alpPath' startOffset='0%' textAnchor='start'>
            {alpText}
          </textPath>
        </text>

        {/* Text following the counterclockwise square path */}
        <text fill='black' fontSize='15' style={{ letterSpacing: '1px' }}>
          <textPath
            href='#uuidPath'
            startOffset='4%'
            textAnchor='start'
            side='left'
          >
            {firstPart}
          </textPath>
        </text>
        {/* Text following the counterclockwise square path */}
        <text fill='black' fontSize='15' style={{ letterSpacing: '1px' }}>
          <textPath
            href='#uuidPath'
            startOffset='25%'
            textAnchor='start'
            side='left'
          >
            {secondPart}
          </textPath>
        </text>
      </svg>
    </div>
  );
};

export default BookQRCode;

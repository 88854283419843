import React, { Component } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import {
  Button,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
} from 'reactstrap';
import {
  getBook,
  postBook,
  deleteBook,
  isValidApiResponse,
  searchOrganizations,
} from './OpenGradaranApi.js';
import {
  EmptyBook,
  EmptyEdition,
  EmptyOrganization,
  BookStatus,
  BookStatusOptions,
  BookCondition,
  BookConditionOptions,
} from './Constant.js';
import { backgroundStyle, inputDisableStyle } from './InlineStyles.js';
import {
  DeleteButton,
  CloseButton,
  SaveButton,
  StatusFormGroup,
  Pair,
} from './utils/CustomViewUtil.js';
import { withTranslation } from 'react-i18next';
import { LocalDate } from '@js-joda/core'; // Import LocalDate from js-joda

/* This is a higher order component that
 *  inject a special prop to our component.
 */
function withRouter(Component) {
  function ComponentWithRouter(props) {
    let params = useParams();
    let navigate = useNavigate();
    let location = useLocation();
    return (
      <Component
        {...props}
        params={params}
        navigate={navigate}
        location={location}
      />
    );
  }
  return ComponentWithRouter;
}

class BookForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      book: { ...EmptyBook },
      edition: { ...EmptyEdition },
      parentOrganization: { ...EmptyOrganization },
      organization: { ...EmptyOrganization },
      organizationSearchValue: '', // Value entered in the input box
      organizationOptions: [],
      isDropdownVisible: false,
      isLoading: false, // Loading state for API calls
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleLibraryInputChange = this.handleLibraryInputChange.bind(this);
    this.handleLibraryOptionClick = this.handleLibraryOptionClick.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);

    this.handleClose = this.handleClose.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    if (this.props.params.id !== 'new') {
      const id = this.props.params.id;
      const book = await (await getBook(id)).json();
      // Use the replaceState function to remove the form page from history
      //this.props.history.replace(`/books/${book.id}`);
      this.setState({
        book: book,
        edition: book.edition,
        parentOrganization: book.organization?.parentOrganization,
        organization: book.organization,
        organizationSearchValue: book.organization?.name || '',
      });
    } else {
      // Use the replaceState function to remove the form page from history
      //this.props.history.replace(`/books`);
    }

    //this.findAndSetLibrary();

    var edition = this.props.location.state?.edition;
    if (edition) {
      this.setState({
        edition: edition,
      });
    }
  }

  handleKeyDown = (event) => {
    if (event.key == 'Enter') {
      event.preventDefault(); // Prevent the default behavior of the "Enter" key
    }
  };

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    let book = { ...this.state.book };
    if (name == 'dueDate') {
      book[name] = LocalDate.parse(value);
    } else {
      book[name] = value;
    }
    this.setState({ book });
  }

  handleLibraryOptionClick(selectedLibrary) {
    this.setState({
      organization: selectedLibrary,
      organizationSearchValue: selectedLibrary?.name,
      parentOrganization: selectedLibrary.parentOrganization,
      isDropdownVisible: false,
    });
  }

  async handleLibraryInputChange(event) {
    event.stopPropagation();
    const value = event.target.value;
    this.setState({ organizationSearchValue: value, isLoading: true });

    this.findAndSetLibrary(value);
    if (value.length > 0) {
      this.setState({ isLoading: false, isDropdownVisible: true });
    } else {
      this.setState({ isLoading: false, isDropdownVisible: false });
    }
  }

  async findAndSetLibrary(value = '') {
    try {
      const optionsResponse = await (await searchOrganizations(value)).json();
      if (isValidApiResponse(optionsResponse.message)) {
        const options = [EmptyOrganization, ...optionsResponse.data.page];
        this.setState({
          organizationOptions: options,
        });
      } else {
        // handle errors
        console.error('Error fetching data:', optionsResponse.message);
      }
    } catch (error) {
      // handle errors
      console.error('Error fetching data:', error);
    }
  }

  async handleSubmit(event) {
    event.preventDefault();
    const { book, organization, edition } = this.state;

    book.organization = organization?.id ? organization : null;
    book.edition = edition;

    var bookResponse = await (await postBook(book)).json();
    if (isValidApiResponse(bookResponse)) {
      this.props.navigate('/editions/' + edition.id + '/books', {
        state: { edition },
      });
    } else {
      //report error
    }
  }

  async handleDelete(event) {
    event.preventDefault();

    // Display a confirmation dialog before proceeding with the delete action
    const isConfirmed = window.confirm(
      'Are you sure you want to delete this book?'
    );

    if (!isConfirmed) {
      // If the user cancels, exit the function
      return;
    }

    const { book, organization, edition } = this.state;
    if (book.id) {
      try {
        const bookResponse = await deleteBook(book.id);
        if (isValidApiResponse(bookResponse)) {
          this.props.navigate('/books');
        } else {
          // Report invalid API response
          console.error('Invalid API response');
        }
      } catch (error) {
        // Handle network or other errors
        console.error('Error deleting book:', error);
      }
    } else {
      this.props.navigate(-1);
    }
  }

  handleClose(event) {
    event.preventDefault();
    this.props.navigate(-1);
  }

  render() {
    const { t } = this.props;
    const {
      book,
      edition,
      parentOrganization,
      organization,
      organizationOptions,
      organizationSearchValue,
      isLoading,
      isDropdownVisible,
    } = this.state;
    if (!book) {
      return;
    }
    const title = <h2>{book.id ? t('Edit Book') : t('Add Book')}</h2>;

    const editionView = edition?.id ? (
      <h3>
        <Pair
          label={'Edition'}
          value={edition.title}
          valueClass={'custom-text-blue'}
        />
      </h3>
    ) : null;

    return (
      <div>
        <div style={backgroundStyle}>
          <Container className='green-white'>
            <Row className='form-container'>
              {title}
              <Form onSubmit={this.handleSubmit}>
                {editionView}
                <Row>
                  <Col>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label htmlFor='parent'>
                            {t('Parent Organization')}
                          </Label>
                          <Input
                            type='text'
                            name='parent'
                            id='parent'
                            value={parentOrganization?.name}
                            onChange={this.handleChange}
                            onKeyDown={this.handleKeyDown}
                            autoComplete='name'
                            readOnly // Add the readOnly attribute here
                            style={inputDisableStyle}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>

                  <Col>
                    <FormGroup>
                      <Label htmlFor='organization'>{t('Library')}</Label>
                      <Input
                        type='text'
                        value={organizationSearchValue}
                        onChange={this.handleLibraryInputChange}
                        onKeyDown={this.handleKeyDown}
                        className='form-control'
                        placeholder={t('Search...')}
                      />
                      {isDropdownVisible && !isLoading && (
                        <div className='dropdown-menu show'>
                          {organizationOptions.map((option) => (
                            <Button
                              key={option.id}
                              className='dropdown-item'
                              onClick={() =>
                                this.handleLibraryOptionClick(option)
                              }
                            >
                              {option.name}
                            </Button>
                          ))}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label htmlFor='id'>{t('Book ID')}</Label>
                      <Input
                        type='text'
                        name='id'
                        id='id'
                        value={book.id}
                        onChange={this.handleChange}
                        onKeyDown={this.handleKeyDown}
                        autoComplete='name'
                        readOnly // Add the readOnly attribute here
                        style={{
                          background: '#f0f0f0',
                          border: 'none',
                          boxShadow: 'none',
                          cursor: 'not-allowed',
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label htmlFor='uuid'>{t('Book UUID')}</Label>
                      <Input
                        type='text'
                        name='uuid'
                        id='uuid'
                        value={book.uuid}
                        onChange={this.handleChange}
                        onKeyDown={this.handleKeyDown}
                        autoComplete='name'
                      />
                    </FormGroup>
                  </Col>

                  <Col>
                    <FormGroup>
                      <Label htmlFor='callNumber'>Book Call Number</Label>
                      <Input
                        type='text'
                        name='callNumber'
                        id='callNumber'
                        value={book.callNumber}
                        onChange={this.handleChange}
                        onKeyDown={this.handleKeyDown}
                        autoComplete='name'
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label htmlFor='status'>{t('Book Status')}</Label>
                      <select
                        value={book.bookStatus}
                        defaultValue={BookStatus.UNDEFINED}
                        onChange={this.handleChange}
                        className='form-control'
                        name='bookStatus'
                        id='bookStatus'
                      >
                        {BookStatusOptions.map((option) => (
                          <option value={option.value}>{option.label}</option>
                        ))}
                      </select>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label htmlFor='condition'>{t('Book Condition')}</Label>
                      <select
                        value={book.condition}
                        defaultValue={BookCondition.UNDEFINED}
                        onChange={this.handleChange}
                        className='form-control'
                        name='condition'
                        id='condition'
                      >
                        {BookConditionOptions.map((option) => (
                          <option value={option.value}>{option.label}</option>
                        ))}
                      </select>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label htmlFor='dueDate'>{t('Due Date')}</Label>
                      <Input
                        type='date'
                        name='dueDate'
                        id='dueDate'
                        value={book.dueDate ? book.dueDate.toString() : ''}
                        onChange={this.handleChange}
                        onKeyDown={this.handleKeyDown}
                        autoComplete='name'
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label htmlFor='reader'>Reader ID</Label>
                      <Input
                        type='text'
                        name='reader'
                        id='reader'
                        value={book.reader?.id || ''}
                        onChange={this.handleChange}
                        onKeyDown={this.handleKeyDown}
                        autoComplete='name'
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup>
                  <Label htmlFor='notes'>{t('Notes')}</Label>
                  <Input
                    type='textarea'
                    rows={5}
                    name='notes'
                    id='notes'
                    value={book.notes}
                    onChange={this.handleChange}
                    onKeyDown={this.handleKeyDown}
                    autoComplete='name'
                  />
                </FormGroup>
                <StatusFormGroup
                  status={book.status}
                  handleChange={this.handleChange}
                />
                <FormGroup>
                  <SaveButton />
                  <CloseButton onClick={this.handleClose} />
                  <DeleteButton onClick={this.handleDelete} />
                </FormGroup>
              </Form>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}
const HOCBookForm = withRouter(withTranslation()(BookForm));
export default HOCBookForm;

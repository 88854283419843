import React from 'react';
import ReactDOM from 'react-dom';
import jsPDF from 'jspdf';
import * as htmlToImage from 'html-to-image';
import BookQRCode from './utils/BookQRCode';

const GeneratePDFWithBookQRCodes = () => {
  const uuids = [
    '490ab8ae-1d25-4709-b32b-30d385d47974',
    '123e4567-e89b-12d3-a456-426614174001',
    '8bb1bec6-c61e-460f-b7a0-05d23fdd5f77',
    'c8381501-b615-4d81-9a4f-5b9af08ac29e',
    '8664b2c1-61a4-4827-b74d-6b3247517905',
    'a56c6fe7-1197-4925-86bc-3087583d0695',
  ];

  const generatePDFWithQRCodes = async (uuids) => {
    const qrCodeSize = 90; // QR code size in mm
    const spacing = 10; // Spacing between QR codes in mm
    const pageWidth = 210; // A4 width in mm
    const pageHeight = 297; // A4 height in mm
    const startX = (pageWidth - 2 * qrCodeSize - spacing) / 2; // Center the grid horizontally
    const startY = 5; // Reduced startY margin from 50 to 5 mm for better use of space
    const rowHeight = qrCodeSize + spacing; // Height for each row

    const doc = new jsPDF();

    // Loop through each UUID
    let row = 0;
    let col = 0;

    for (const [index, uuid] of uuids.entries()) {
      // Calculate the X and Y position of each QR code
      const x = startX + col * (qrCodeSize + spacing);
      const y = startY + row * rowHeight;

      // Create a temporary DOM node to render BookQRCode
      const container = document.createElement('div');
      document.body.appendChild(container);

      // Render BookQRCode into the temporary DOM node
      await new Promise((resolve) => {
        ReactDOM.render(<BookQRCode bookUUID={uuid} />, container, resolve);
      });

      const qrCodeDiv = container.firstChild;

      // Wait for QRCodeCanvas to fully render
      await new Promise((r) => setTimeout(r, 200)); // Adjust delay as needed

      // Capture the rendered content as an image
      await htmlToImage.toCanvas(qrCodeDiv).then((finalCanvas) => {
        const imgData = finalCanvas.toDataURL('image/png');
        doc.addImage(imgData, 'PNG', x, y, qrCodeSize, qrCodeSize);
      });

      // Add dividers between the QR codes (vertical and horizontal lines)
      if (col < 1) {
        // Vertical divider between columns
        doc.line(x + qrCodeSize, y, x + qrCodeSize, y + qrCodeSize);
      }
      if (row < 2) {
        // Horizontal divider between rows
        doc.line(
          x,
          y + qrCodeSize,
          x + qrCodeSize * 2 + spacing,
          y + qrCodeSize
        );
      }

      // Clean up temporary DOM node
      ReactDOM.unmountComponentAtNode(container);
      document.body.removeChild(container);

      // Update row and column for the next QR code
      col += 1;
      if (col === 2) {
        col = 0;
        row += 1;
      }
    }

    // Save the PDF
    doc.save('QRCodes.pdf');
  };

  return (
    <div>
      <button onClick={() => generatePDFWithQRCodes(uuids)}>
        Generate PDF
      </button>
    </div>
  );
};

export default GeneratePDFWithBookQRCodes;

import React, { createContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ResourceName } from './Constant.js';
import { jwtDecode } from 'jwt-decode';

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const { i18n } = useTranslation();

  const [appState, setAppState] = useState({
    searchQuery: '',
    selectedPage: 0,
    selectedResource: ResourceName.UNDEFINED,
    username: localStorage.getItem('username') || null,
    language: localStorage.getItem('language') || 'en',
  });

  // Update language in i18n and localStorage when it changes
  useEffect(() => {
    i18n.changeLanguage(appState.language);
    localStorage.setItem('language', appState.language);
  }, [appState.language, i18n]);

  // Language toggle
  const toggleLanguage = () =>
    setAppState((prev) => ({
      ...prev,
      language: prev.language === 'en' ? 'hy' : 'en',
    }));

  // Update selected page and resource
  const updateAppSelectedPage = (selectedResource, selectedPage) => {
    setAppState((prevState) => ({
      ...prevState,
      selectedPage,
      selectedResource,
    }));
  };

  // Login and store user data
  const login = (user, token, role) => {
    const decodedToken = jwtDecode(token);
    const expirationTime = decodedToken.exp * 1000; // Convert to milliseconds

    setAppState((prevState) => ({
      ...prevState,
      username: user.username,
    }));
    localStorage.setItem('username', user.username);
    localStorage.setItem('role', role);
    localStorage.setItem('token', token);
    localStorage.setItem('tokenExpiration', expirationTime);
  };

  // Logout and clear user data
  const logout = () => {
    setAppState((prevState) => ({
      ...prevState,
      username: null,
    }));
    localStorage.removeItem('username');
    localStorage.removeItem('role');
    localStorage.removeItem('token');
    localStorage.removeItem('tokenExpiration');
    localStorage.removeItem('language');

    window.location.href = '/login';
  };

  // Schedule automatic logout based on token expiration
  const scheduleAutoLogout = () => {
    const tokenExpiration = localStorage.getItem('tokenExpiration');
    if (!tokenExpiration) {
      return;
    }
    const timeRemaining = tokenExpiration - Date.now();
    if (timeRemaining > 0) {
      setTimeout(() => {
        alert('Session expired. Logging out...');
        logout();
      }, timeRemaining);
    } else {
      logout();
    }
  };

  // Check token validity on load
  const checkTokenOnLoad = () => {
    const tokenExpiration = localStorage.getItem('tokenExpiration');
    if (tokenExpiration && Date.now() > tokenExpiration) {
      alert('Session expired. Logging out...');
      logout();
    } else {
      scheduleAutoLogout();
    }
  };

  return (
    <AppContext.Provider
      value={{
        appState,
        setAppState,
        updateAppSelectedPage,
        login,
        logout,
        checkTokenOnLoad,
        toggleLanguage,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

import React, { Component } from 'react';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import {
  getBook,
  getBookByUuid,
  getDonationByBookId,
} from './OpenGradaranApi.js';
import {
  EmptyBook,
  EmptyDonation,
  EmptyOrganization,
  BookConditionOptions,
  BookStatusOptions,
  getLabelByOptionValue,
  canEditContent,
} from './Constant.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import {
  ViewTitle,
  BackButton,
  BookCoverBig,
  Pair,
} from './utils/CustomViewUtil.js';
import { backgroundStyle } from './InlineStyles.js';
import { withTranslation } from 'react-i18next';
import { AppContext } from './AppContext.js';
import {
  getText,
  createTranslator,
  CaseFormat,
} from './utils/TranslationUtil.js';

/* This is a higher order component that
 *  inject a special prop to our component.
 */
function withRouter(Component) {
  function ComponentWithRouter(props) {
    let params = useParams();
    let navigate = useNavigate();
    let location = useLocation();
    return (
      <Component
        {...props}
        params={params}
        navigate={navigate}
        location={location}
      />
    );
  }
  return ComponentWithRouter;
}

class BookPage extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);

    this.state = {
      book: { ...EmptyBook },
      donation: { ...EmptyDonation },
    };
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
  }

  async componentDidMount() {
    const id = this.props.params.id;
    const uuid = this.props.params.uuid;
    var book = this.props.location.state?.book;
    if (!book) {
      if (id) {
        const bookResp = await (await getBook(id)).json();
        if (bookResp.message == null) {
          book = bookResp;
        } else {
          // handle error
        }
      } else if (uuid) {
        const bookRespByUuid = await (await getBookByUuid(uuid)).json();
        if (bookRespByUuid.message == null) {
          book = bookRespByUuid;
        } else {
          // handle error
        }
      }
    }
    book = book || EmptyBook;

    var donation = this.props.location.state?.donation;
    if (!donation) {
      const donationResp = await (await getDonationByBookId(book.id)).json();
      if (donationResp.message == null) {
        donation = donationResp;
      } else {
        // handle error
        donation = EmptyDonation;
      }
    }
    donation = donation || EmptyDonation;

    this.setState({ book, donation });
  }

  handleEditClick(event) {
    event.preventDefault();
    console.log('Icon clicked!');
    const { book } = this.state;
    this.props.navigate(`/books/${book.id}`, {
      state: { book },
    });
  }

  handleGoBack = () => {
    this.props.navigate(-1); // Use history.goBack() to navigate back
  };

  render() {
    const { t } = this.props;
    const translate = createTranslator(t);
    const { appState } = this.context;
    const language = appState.language;

    const { book, donation } = this.state;
    const edition = book.edition;

    const bookOrgName = getText(
      book.organization?.name,
      book.organization?.nameAm,
      language
    );

    const bookCondition = getLabelByOptionValue(
      BookConditionOptions,
      book.condition
    );

    const bookStatus = getLabelByOptionValue(
      BookStatusOptions,
      book.bookStatus
    );

    const donationView = donation.id ? (
      <Link
        to={`/donations/page/${donation.id}`}
        state={{
          book,
          donation,
          organization: donation.organization, // book provider organization
        }}
        className='custom-green'
      >
        {t('Donation Info')}
      </Link>
    ) : (
      // <DonationView donation={donation} organization={donation.organization} />
      <Link
        to={'/donations/new'}
        state={{
          book,
          donation: { ...EmptyDonation },
          organization: { ...EmptyOrganization },
        }}
        className='custom-green'
      >
        {'Add Donation Info'}
      </Link>
    );

    return (
      <div>
        <div style={backgroundStyle}>
          <Container
            className='light-blue-white'
            style={{ width: '70%', maxWidth: '820px' }}
          >
            <div
              style={{
                position: 'relative',
                padding: '30px',
              }}
            >
              <Row>
                <Col>
                  <BookCoverBig edition={edition} />
                </Col>
                <Col>
                  <ViewTitle name={edition.title} resourceName={'Book'} />
                  <p>
                    {t('Edition')}
                    {t(': ')}
                    <Link
                      to={`/editions/page/${edition.id}`}
                      state={{ edition }}
                      className='custom-green'
                    >
                      {edition.title}
                    </Link>
                  </p>
                  <Pair label={'ISBN-13'} value={edition.isbn13} />
                  <Pair label={'ISBN-10'} value={edition.isbn10} />
                  <Pair label={'Language'} value={edition.language} />
                  <Pair label={'Page count'} value={edition.pageCount} />
                  <p>
                    {t('Location')}
                    {t(': ')}
                    <Link
                      to={`/organizations/page/${book.organization?.id}`}
                      state={{ organization: book.organization }}
                      className='custom-green'
                    >
                      {bookOrgName}
                    </Link>
                  </p>
                  <Pair label={'Book ID'} value={book.id} />
                  <Pair label={'Book UUID'} value={book.uuid} />
                  <Pair
                    label={'Book status'}
                    value={translate(bookStatus, CaseFormat.CAPITALIZED)}
                  />
                  <Pair
                    label={'Book condition'}
                    value={translate(bookCondition, CaseFormat.CAPITALIZED)}
                  />
                  <p>
                    <span className='custom-green'>{book.notes}</span>
                  </p>

                  {donationView}
                </Col>
              </Row>

              <p>
                <BackButton onClick={this.handleGoBack} />
              </p>

              {/* Edit icon at the upper right corner */}
              {canEditContent() && (
                <div className='edit-pencil-container'>
                  <a href='' onClick={this.handleEditClick}>
                    <FontAwesomeIcon
                      icon={faPencilAlt}
                      className='edit-pencil'
                    />
                  </a>
                </div>
              )}
            </div>
          </Container>
        </div>
      </div>
    );
  }
}
const HOCBookPage = withRouter(withTranslation()(BookPage));
export default HOCBookPage;

import React, { Component } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import {
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Table,
  Row,
  Col,
  Button,
} from 'reactstrap';
import {
  Language,
  LanguageOptions,
  EmptyWork,
  EmptyEdition,
  EmptyAuthor,
  EmptySeries,
} from './Constant.js';
import { backgroundStyle, inputDisableStyle } from './InlineStyles.js';
import {
  getWork,
  postWork,
  addWorkToAuthor,
  addWorkToEdition,
  isValidApiResponse,
  deleteWork,
  deleteAuthor,
  searchSeries,
} from './OpenGradaranApi.js';
import {
  FormTitle,
  DeleteButton,
  DeleteButtonSmall,
  CloseButton,
  SaveButton,
  StatusFormGroup,
} from './utils/CustomViewUtil.js';
import { withTranslation } from 'react-i18next';

/* This is a higher order component that
 *  inject a special prop to our component.
 */
function withRouter(Component) {
  function ComponentWithRouter(props) {
    let params = useParams();
    let navigate = useNavigate();
    let location = useLocation();
    return (
      <Component
        {...props}
        params={params}
        navigate={navigate}
        location={location}
      />
    );
  }
  return ComponentWithRouter;
}

class WorkForm extends React.Component {
  state = {
    work: { ...EmptyWork },
    edition: { ...EmptyEdition },
    author: { ...EmptyAuthor },
  };

  constructor(props) {
    super(props);
    this.state = {
      work: { ...EmptyWork },
      edition: { ...EmptyEdition },
      author: { ...EmptyAuthor },
      series: { ...EmptySeries },
      seriesSearchValue: '', // Value entered in the input box
      seriesOptions: [],
      isDropdownVisible: false,
      isLoading: false, // Loading state for API calls
    };
    this.handleRemoveAuthorFromWork =
      this.handleRemoveAuthorFromWork.bind(this);
    this.handleWorkChange = this.handleWorkChange.bind(this);
    this.handleSeriesInputChange = this.handleSeriesInputChange.bind(this);
    this.handleSeriesOptionClick = this.handleSeriesOptionClick.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);

    this.handleDelete = this.handleDelete.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    var work = this.props.location.state?.work;
    var edition = this.props.location.state?.edition;
    var author = this.props.location.state?.author;
    var series = work?.series ||
      this.props.location.state?.series || { ...EmptySeries };
    if (!work?.id && this.props.params.id !== 'new') {
      const id = this.props.params.id;
      work = await (await getWork(id)).json();
      series = work.series;
    }

    this.setState({
      work,
      edition,
      author,
      series,
      seriesSearchValue: series.title,
    });
  }

  handleKeyDown = (event) => {
    if (event.key == 'Enter') {
      event.preventDefault(); // Prevent the default behavior of the "Enter" key
    }
  };

  handleWorkChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    let work = this.state.work;
    work[name] = value;

    this.setState((state) => {
      return { work: work };
    });
  }

  async handleSeriesInputChange(event) {
    event.stopPropagation();
    const value = event.target.value;
    this.setState({ seriesSearchValue: value, isLoading: true });

    this.findAndSetSeries(value);
    if (value.length > 0) {
      this.setState({ isLoading: false, isDropdownVisible: true });
    } else {
      this.setState({ isLoading: false, isDropdownVisible: false });
    }
  }

  handleSeriesOptionClick(selectedSeries) {
    let work = this.state.work;
    work.series = selectedSeries;
    this.setState({
      series: selectedSeries,
      seriesSearchValue: selectedSeries?.title,
      isDropdownVisible: false,
    });
  }

  async findAndSetSeries(value = '') {
    try {
      const optionsResponse = await (await searchSeries(value)).json();
      if (isValidApiResponse(optionsResponse.message)) {
        const options = [EmptySeries, ...optionsResponse.data.page];
        this.setState({
          seriesOptions: options,
        });
      } else {
        // handle errors
        console.error('Error fetching data:', optionsResponse.message);
      }
    } catch (error) {
      // handle errors
      console.error('Error fetching data:', error);
    }
  }

  async handleRemoveAuthorFromWork(i, author) {
    //event.preventDefault();

    // Display a confirmation dialog before proceeding with the delete action
    const isConfirmed = window.confirm(
      'Are you sure you want to remove this author?'
    );

    if (!isConfirmed) {
      // If the user cancels, exit the function
      return;
    }
    let work = { ...this.state.work };
    let authors = work.authors;

    // try {
    //   const removeAuthorResponse = await deleteAuthor(authors[i].id);
    //   if (isValidApiResponse(removeAuthorResponse)) {
    //     this.setState((state) => {
    //       return { authors };
    //     });
    //   } else {
    //     // Report invalid API response
    //     console.error('Invalid API response');
    //   }
    // } catch (error) {
    //   // Handle network or other errors
    //   console.error('Error deleting author:', error);
    // }
  }

  async handleSubmit(event) {
    event.preventDefault();
    const { work, edition, author } = this.state;

    if (work.series?.title?.trim().length === 0) {
      work.series = null;
    }

    // post work
    var workResponse = null;
    if (edition?.id) {
      workResponse = await (await addWorkToEdition(edition.id, work)).json();
    } else if (author?.id) {
      workResponse = await (await addWorkToAuthor(author.id, work)).json();
    } else {
      workResponse = await (await postWork(work)).json();
    }

    if (isValidApiResponse(workResponse)) {
      this.props.navigate('/works');
    } else {
      //report error
    }
  }

  async handleDelete(event) {
    event.preventDefault();

    // Display a confirmation dialog before proceeding with the delete action
    const isConfirmed = window.confirm(
      'Are you sure you want to delete this work?'
    );

    if (!isConfirmed) {
      // If the user cancels, exit the function
      return;
    }

    const { work } = this.state;
    if (work.id) {
      try {
        const workResponse = await deleteWork(work.id);
        if (isValidApiResponse(workResponse)) {
          this.props.navigate('/works');
        } else {
          // Report invalid API response
          console.error('Invalid API response');
        }
      } catch (error) {
        // Handle network or other errors
        console.error('Error deleting work:', error);
      }
    } else {
      this.props.navigate(-1);
    }
  }

  handleClose(event) {
    event.preventDefault();
    this.props.navigate(-1);
  }

  render() {
    const { t } = this.props;
    const {
      edition,
      work,
      author,
      seriesOptions,
      seriesSearchValue,
      isLoading,
      isDropdownVisible,
    } = this.state;

    let authors = work.authors;

    const authorGroupList = authors.map((author, i) => {
      return (
        <tr key={i}>
          <Row>
            <Col>
              <FormGroup>
                <Label htmlFor='Name'>{author.name}</Label>
                <DeleteButtonSmall
                  onClick={(author) =>
                    this.handleRemoveAuthorFromWork(i, author)
                  }
                />
              </FormGroup>
            </Col>
          </Row>
        </tr>
      );
    });
    return (
      <div>
        <div style={backgroundStyle}>
          <Container className='green-white'>
            <div className='form-container'>
              {author?.id && (
                <FormTitle
                  id={author?.id}
                  name={author?.name}
                  resourceName={'Author'}
                />
              )}
              {edition?.id && (
                <FormTitle
                  id={edition?.id}
                  name={edition?.title}
                  resourceName={'Edition'}
                />
              )}

              {
                <FormTitle
                  id={work.id}
                  name={work.title}
                  resourceName={'Work'}
                />
              }
              <Form onSubmit={this.handleSubmit}>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label htmlFor='id'>{t('Work ID')}</Label>
                      <Input
                        type='text'
                        name='id'
                        id='id'
                        value={work.id}
                        onChange={this.handleWorkChange}
                        autoComplete='name'
                        readOnly
                        style={inputDisableStyle}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label htmlFor='openLibraryId'>Open Library ID</Label>
                      <Input
                        type='text'
                        name='openLibraryId'
                        id='openLibraryId'
                        value={work.openLibraryId}
                        onChange={this.handleWorkChange}
                        onKeyDown={this.handleKeyDown}
                        autoComplete='name'
                        placeholder='/works/XXXXXXXXXX'
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label htmlFor='worldCat'>WorldCat</Label>
                      <Input
                        type='text'
                        name='worldCat'
                        id='worldCat'
                        value={work.worldCat}
                        onChange={this.handleWorkChange}
                        onKeyDown={this.handleKeyDown}
                        autoComplete='name'
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup>
                  <Label htmlFor='title'>{t('Title')}</Label>
                  <Input
                    type='text'
                    name='title'
                    id='title'
                    value={work.title}
                    required
                    placeholder='enter work title'
                    onChange={this.handleWorkChange}
                    onKeyDown={this.handleKeyDown}
                    autoComplete='name'
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor='subtitle'>{t('Subtitle')}</Label>
                  <Input
                    type='text'
                    name='subtitle'
                    id='subtitle'
                    value={work.subtitle}
                    onChange={this.handleWorkChange}
                    onKeyDown={this.handleKeyDown}
                    autoComplete='name'
                  />
                </FormGroup>

                <FormGroup>
                  <Label htmlFor='description'>{t('Description')}</Label>
                  <Input
                    type='textarea'
                    rows={5}
                    name='description'
                    id='description'
                    value={work.description}
                    onChange={this.handleWorkChange}
                    onKeyDown={this.handleKeyDown}
                    autoComplete='name'
                  />
                </FormGroup>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label htmlFor='originalLanguage'>
                        {t('Original Language')}
                      </Label>
                      <select
                        value={work.originalLanguage}
                        defaultValue={Language.ENGLISH}
                        onChange={this.handleWorkChange}
                        className='form-control'
                        name='originalLanguage'
                        id='originalLanguage'
                      >
                        {LanguageOptions.map((option) => (
                          <option value={option.value}>{option.label}</option>
                        ))}
                      </select>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label htmlFor='series'>{t('Series')}</Label>
                      <Input
                        type='text'
                        value={seriesSearchValue}
                        onChange={this.handleSeriesInputChange}
                        onKeyDown={this.handleKeyDown}
                        className='form-control'
                        placeholder={t('Search...')}
                      />
                      {isDropdownVisible && !isLoading && (
                        <div className='dropdown-menu show'>
                          {seriesOptions.map((option) => (
                            <Button
                              key={option.id}
                              className='dropdown-item'
                              onClick={() =>
                                this.handleSeriesOptionClick(option)
                              }
                            >
                              {option.title}
                            </Button>
                          ))}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label htmlFor='seriesItemNumber'>
                        {t('Series Item Number')}
                      </Label>
                      <Input
                        type='text'
                        name='seriesItemNumber'
                        id='seriesItemNumber'
                        value={work.seriesItemNumber}
                        onChange={this.handleWorkChange}
                        onKeyDown={this.handleKeyDown}
                        autoComplete='name'
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label htmlFor='volumeNumber'>{t('Volume Number')}</Label>
                      <Input
                        type='text'
                        name='volumeNumber'
                        id='volumeNumber'
                        value={work.volumeNumber}
                        onChange={this.handleWorkChange}
                        onKeyDown={this.handleKeyDown}
                        autoComplete='name'
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Table>
                  <tbody className='green-white'>
                    <tr>
                      <td
                        colSpan='100%'
                        style={{ fontWeight: 'bold', textAlign: 'left' }}
                      >
                        Authors
                      </td>
                    </tr>
                    {authorGroupList}
                  </tbody>
                </Table>
                <StatusFormGroup
                  status={work.status}
                  handleChange={this.handleWorkChange}
                />
                <FormGroup>
                  <SaveButton />
                  <CloseButton onClick={this.handleClose} />
                  <DeleteButton onClick={this.handleDelete} />
                </FormGroup>
              </Form>
            </div>
          </Container>
        </div>
      </div>
    );
  }
}

const HOCWorkForm = withRouter(withTranslation()(WorkForm));
export default HOCWorkForm;

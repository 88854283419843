import backgroundImage from './images/ALP_Flyer_020623_00.jpg';
export const backgroundStyle = {
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: 'cover', // Ensures the image covers the container
  backgroundPosition: 'center', // Centers the image
  backgroundRepeat: 'no-repeat', // Prevents repetition
  minHeight: '100vh', // Ensures it covers the full height of the viewport
  width: '100%', // Ensures full width of the container
  alignItems: 'center',
  paddingTop: '40px',
  paddingLeft: '20px',
  paddingRight: '20px',
  paddingBottom: '140px',
  display: 'flex', // Allows children to be flex-aligned if necessary
  flexDirection: 'column', // Ensures children stack vertically if flexbox is used
};

export const inputDisableStyle = {
  background: '#f0f0f0',
  border: 'none',
  boxShadow: 'none',
  cursor: 'not-allowed',
};

export const customNavbarStyle = {
  marginBottom: '100px',
};

export const formButtonStyle = {
  backgroundColor: 'transparent',
  color: 'white',
  width: '50px',
  height: 'auto',
  margin: '5px',
  border: '0px',
};

export const formButtonIconStyle = {
  backgroundColor: 'transparent',
  color: 'white',
  width: '30px',
  height: 'auto',
};

export const formSmallButtonIconStyle = {
  backgroundColor: 'transparent',
  color: 'white',
  width: '15px',
  height: 'auto',
};

export const transparentContainerStyle = {
  alignItems: 'center',
  backgroundColor: 'transparent',
  color: 'white',
  // width: '30px',
  // height: 'auto',
};

const baseContainerStyle = {
  display: 'flex',
  alignItems: 'center',
  marginBottom: '20px',
};

export const rightAlignStyle = {
  textAlign: 'right',
  color: 'white',
  width: '100%', // Ensures the div takes the full width
  fontStyle: 'italic', // Makes the font italic
  fontFamily: 'Arial, sans-serif', // Sets the font to Arial
};

export const headerAlignStyle = {
  textAlign: 'center',
  color: 'white',
  width: '100%', // Ensures the div takes the full width
  fontStyle: 'italic', // Makes the font italic
  fontFamily: 'Arial, sans-serif', // Sets the font to Arial
};

export const containerStyle = baseContainerStyle;

export const containerStyleWithCursor = {
  ...baseContainerStyle,
  cursor: 'pointer',
};

export const iconStyle = {
  marginRight: '5px', // Adjust the spacing between the icon and text as needed
};

export const linkStyleNoUnderline = {
  textDecoration: 'none', // Remove the underline
  color: 'link', // Inherit the color from the parent
  display: 'block', // Ensure the link takes up the full width
  textAlign: 'left', // Align text to the left
  // whiteSpace: 'nowrap' /* Prevents the text from wrapping */,
  overflow: 'hidden' /* Hides the overflowed text */,
  textOverflow: 'ellipsis',
  // maxWidth: '300px',
};

import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import {
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
  Button,
} from 'reactstrap';
import {
  getAuthor,
  postAuthor,
  deleteAuthor,
  addAuthorToWork,
  isValidApiResponse,
  searchAuthorsByKeyword,
} from './OpenGradaranApi.js';
import { EmptyAuthor, EmptyWork } from './Constant.js';
import { backgroundStyle, inputDisableStyle } from './InlineStyles.js';
import {
  FormTitle,
  DeleteButton,
  CloseButton,
  SaveButton,
  StatusFormGroup,
} from './utils/CustomViewUtil.js';
import { useTranslation } from 'react-i18next';

const AuthorForm = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [author, setAuthor] = useState(
    location.state?.author || { ...EmptyAuthor }
  );
  const [work, setWork] = useState(location.state?.work || { ...EmptyWork });
  const [authorOptions, setAuthorOptions] = useState([]);
  const [authorSearchValue, setAuthorSearchValue] = useState(author.name || '');
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const fetchAuthor = async () => {
      if (!author.id && id !== 'new') {
        const fetchedAuthor = await (await getAuthor(id)).json();
        setAuthor(fetchedAuthor);
        setAuthorSearchValue(fetchAuthor.name);
      }
    };

    fetchAuthor();
  }, [id]);

  const handleAuthorInputChange = async (event) => {
    const value = event.target.value;
    setLoading(true);
    author.name = value;
    setAuthorSearchValue(value);
    setDropdownVisible(value.length > 0);

    await findAndSetAuthorOptions(value);
    setLoading(false);
  };

  const handleAuthorOptionClick = (selectedAuthor) => {
    setAuthor(selectedAuthor);
    setAuthorSearchValue(selectedAuthor.name);
    setDropdownVisible(false);
  };

  const findAndSetAuthorOptions = async (value = '') => {
    try {
      const response = await searchAuthorsByKeyword(value);
      const optionsResponse = await response.json();

      if (isValidApiResponse(optionsResponse.message)) {
        const options = [...optionsResponse.data.page];
        setAuthorOptions(options);
      } else {
        console.error('Error fetching data:', optionsResponse.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setAuthor((prevAuthor) => ({
      ...prevAuthor,
      [name]: value,
    }));
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent the default behavior of the "Enter" key
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    var authorResponse = null;
    if (work?.id) {
      authorResponse = await (await addAuthorToWork(work.id, author)).json();
    } else {
      authorResponse = await (await postAuthor(author)).json();
    }

    if (isValidApiResponse(authorResponse)) {
      navigate('/authors');
    } else {
      // report error
    }
  };

  const handleClose = (event) => {
    console.log('Close clicked!');
    event.preventDefault();
    navigate(-1);
  };

  const handleDelete = async (event) => {
    event.preventDefault();

    // Display a confirmation dialog before proceeding with the delete action
    const isConfirmed = window.confirm(
      'Are you sure you want to delete this author?'
    );

    if (!isConfirmed) {
      // If the user cancels, exit the function
      return;
    }

    if (author.id) {
      try {
        const authorResponse = await deleteAuthor(author.id);
        if (isValidApiResponse(authorResponse)) {
          navigate('/authors');
        } else {
          // Report invalid API response
          console.error('Invalid API response');
        }
      } catch (error) {
        // Handle network or other errors
        console.error('Error deleting author:', error);
      }
    } else {
      navigate(-1);
    }
  };

  return (
    <div>
      <div style={backgroundStyle}>
        <Container className='green-white'>
          <div className='form-container'>
            {work?.id && (
              <FormTitle id={work.id} name={work.title} resourceName={'Work'} />
            )}
            <FormTitle
              id={author.id}
              name={author.name}
              resourceName={'Author'}
            />
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <FormGroup>
                    <Label htmlFor='id'>ID</Label>
                    <Input
                      type='text'
                      name='id'
                      id='id'
                      value={author.id}
                      onChange={handleChange}
                      autoComplete='name'
                      readOnly
                      style={inputDisableStyle}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label htmlFor='openLibraryId'>Open Library ID</Label>
                    <Input
                      type='text'
                      name='openLibraryId'
                      id='openLibraryId'
                      value={author.openLibraryId}
                      placeholder='/authors/XXXXXXXXXX'
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                      autoComplete='name'
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label htmlFor='name'>{t('Author Name')}</Label>
                    <Input
                      type='text'
                      value={authorSearchValue}
                      onChange={handleAuthorInputChange}
                      onKeyDown={handleKeyDown}
                      className='form-control'
                      placeholder={t('enter author name')}
                      required
                    />
                    {isDropdownVisible && !isLoading && (
                      <div className='dropdown-menu show'>
                        {authorOptions.map((option) => (
                          <Button
                            key={option.id}
                            className='dropdown-item'
                            onClick={() => handleAuthorOptionClick(option)}
                          >
                            {option.name}
                          </Button>
                        ))}
                      </div>
                    )}
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label htmlFor='personalName'>{t('Personal Name')}</Label>
                    <Input
                      type='text'
                      name='personalName'
                      id='personalName'
                      value={author.personalName}
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                      autoComplete='name'
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label htmlFor='firstName'>{t('First Name')}</Label>
                    <Input
                      type='text'
                      name='firstName'
                      id='firstName'
                      value={author.firstName}
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                      autoComplete='name'
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label htmlFor='middleName'>{t('Middle Name')}</Label>
                    <Input
                      type='text'
                      name='middleName'
                      id='middleName'
                      value={author.middleName}
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                      autoComplete='name'
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label htmlFor='lastName'>{t('Last Name')}</Label>
                    <Input
                      type='text'
                      name='lastName'
                      id='lastName'
                      value={author.lastName}
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                      autoComplete='name'
                    />
                  </FormGroup>
                </Col>
              </Row>

              <FormGroup>
                <Label htmlFor='bio'>{t('Bio')}</Label>
                <Input
                  type='textarea'
                  rows={5}
                  name='bio'
                  id='bio'
                  value={author.bio}
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                  autoComplete='name'
                />
              </FormGroup>
              <Row>
                <Col>
                  <FormGroup>
                    <Label htmlFor='birthDate'>{t('Birth Date')}</Label>
                    <Input
                      type='text'
                      name='birthDate'
                      id='birthDate'
                      value={author.birthDate}
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                      autoComplete='name'
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label htmlFor='deathDate'>{t('Death Date')}</Label>
                    <Input
                      type='text'
                      name='deathDate'
                      id='deathDate'
                      value={author.deathDate}
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                      autoComplete='name'
                    />
                  </FormGroup>
                </Col>
              </Row>
              <StatusFormGroup
                status={author.status}
                handleChange={handleChange}
              />
              <FormGroup>
                <SaveButton />
                <CloseButton onClick={handleClose} />
                <DeleteButton onClick={handleDelete} />
              </FormGroup>
            </Form>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default AuthorForm;

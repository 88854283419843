import React from 'react';
import { useTranslation } from 'react-i18next';

const AppFooter = () => {
  return (
    <footer style={styles.footer}>
      <p>
        © 2022-2025 Armenian Library Project |{' '}
        <a
          href='mailto:info@armenianlibraryproject.org'
          style={{ color: 'white' }}
        >
          info@armenianlibraryproject.org
        </a>
      </p>
    </footer>
  );
};

const styles = {
  footer: {
    backgroundColor: '#333',
    color: '#fff',
    textAlign: 'center',
    padding: '20px',
    // position: 'fixed',
    left: '0',
    bottom: '0',
    width: '100%',
    overflow: 'hidden', // Prevent scrollbars caused by the footer
    boxSizing: 'border-box', // Ensures padding is included in the width
  },
};

export default AppFooter;

import React, { Component } from 'react';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import { Container } from 'reactstrap';
import { getSeriesById } from './OpenGradaranApi.js';
import { EmptySeries, canEditContent } from './Constant.js';
import { backgroundStyle } from './InlineStyles.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { ViewTitle, BackButton } from './utils/CustomViewUtil.js';
import { withTranslation } from 'react-i18next';
import { AppContext } from './AppContext.js';

/* This is a higher order component that
 *  inject a special prop to our component.
 */
function withRouter(Component) {
  function ComponentWithRouter(props) {
    let params = useParams();
    let navigate = useNavigate();
    let location = useLocation();
    return (
      <Component
        {...props}
        params={params}
        navigate={navigate}
        location={location}
      />
    );
  }
  return ComponentWithRouter;
}

class SeriesPage extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);

    this.state = {
      series: { ...EmptySeries },
    };
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
  }

  async componentDidMount() {
    const id = this.props.params.id;
    var series = this.props.location.state?.series;
    if (!series && id) {
      const seriesResp = await (await getSeriesById(id)).json();
      if (seriesResp.message == null) {
        series = seriesResp;
      } else {
        // handle error
      }
    }
    this.setState({ series: series || EmptySeries });
  }

  handleEditClick(event) {
    event.preventDefault();
    console.log('Icon clicked!');
    const { series } = this.state;
    this.props.navigate(`/series/${series.id}`, { state: { series } });
  }

  handleGoBack = () => {
    this.props.navigate(-1); // Use history.goBack() to navigate back
  };

  render() {
    const { t } = this.props;
    const { appState } = this.context;
    const { series } = this.state;

    return (
      <div>
        <div style={backgroundStyle}>
          <Container
            className='subview-container-blue'
            style={{ width: '50%' }}
          >
            <div
              style={{
                position: 'relative',
                padding: '30px',
              }}
            >
              <ViewTitle name={series.title} resourceName={'Series'} />
              <p>
                <Link
                  to={`/series/${series.id}/works`}
                  state={{ series }}
                  className='custom-green'
                >
                  {t('Works')}
                </Link>
              </p>
              <p>
                <BackButton onClick={this.handleGoBack} />
              </p>
              {/* Edit icon at the upper right corner */}
              {canEditContent() && (
                <div className='edit-pencil-container'>
                  <a href='' onClick={this.handleEditClick}>
                    <FontAwesomeIcon
                      icon={faPencilAlt}
                      className='edit-pencil'
                    />
                  </a>
                </div>
              )}
            </div>
          </Container>
        </div>
      </div>
    );
  }
}
const HOCSeriesPage = withRouter(withTranslation()(SeriesPage));
export default HOCSeriesPage;

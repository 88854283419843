import React, { Component } from 'react';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { getOrganization } from './OpenGradaranApi';
import {
  EmptyOrganization,
  EmptyAddress,
  EmptyContact,
  OrganizationType,
  OrganizationTypeOptions,
  getLabelByOptionValue,
  canEditContent,
} from './Constant.js';
import { backgroundStyle } from './InlineStyles.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import AddressView from './AddressView.js';
import ContactView from './ContactView.js';
import { ViewTitle, BackButton, SchoolImage } from './utils/CustomViewUtil.js';
import { withTranslation } from 'react-i18next';
import { AppContext } from './AppContext.js';
import { getText } from './utils/TranslationUtil.js';

/* This is a higher order component that
 *  inject a special prop to our component.
 */
function withRouter(Component) {
  function ComponentWithRouter(props) {
    let params = useParams();
    let navigate = useNavigate();
    let location = useLocation();
    return (
      <Component
        {...props}
        params={params}
        navigate={navigate}
        location={location}
      />
    );
  }
  return ComponentWithRouter;
}

class OrganizationPage extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);

    this.state = {
      organization: { ...EmptyOrganization },
    };
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
  }

  async componentDidMount() {
    const id = this.props.params.id;
    var organization = this.props.location.state?.organization;
    if (!organization && id) {
      const organizationResp = await (await getOrganization(id)).json();
      if (organizationResp.message == null) {
        organization = organizationResp;
      } else {
        // handle error
      }
    }
    this.setState({ organization: organization || EmptyOrganization });
  }

  handleEditClick(event) {
    event.preventDefault();
    const { organization } = this.state;
    this.props.navigate(`/organizations/${organization.id}`, {
      state: { organization },
    });
  }

  handleGoBack = () => {
    //this.props.navigate('/organizations');
    this.props.navigate(-1);
  };

  render() {
    const { t } = this.props;
    const { appState } = this.context;
    const language = appState.language;

    const { organization } = this.state;
    const address = organization.address;
    const contactGroupList = organization.contacts.map((contact, i) => {
      return (
        <div>
          <p>
            <ContactView contact={contact} organization={organization} />
          </p>
        </div>
      );
    });
    const addressView = address ? (
      <AddressView address={organization.address} organization={organization} />
    ) : (
      canEditContent() && (
        <Link
          to={'/addresses/new'}
          state={{ organization, address: { ...EmptyAddress } }}
          className='custom-green'
        >
          {t('Add Address')}
        </Link>
      )
    );

    let alpLink = null;
    let librariesLink = null;
    let donationEventsLink = null;
    let donationsLink = null;
    let shippingsLink = null;
    let booksLink = null;

    const addContactLink = canEditContent() && (
      <p>
        <Link
          to={'/contacts/new'}
          state={{
            organization,
            contact: { ...EmptyContact },
          }}
          className='custom-green'
        >
          {t('Add Contact')}
        </Link>
      </p>
    );

    if (organization.alpParticipant) {
      alpLink = (
        <Link to={`/about-us`} className='custom-green'>
          {t('ALP')}
        </Link>
      );
    }

    switch (organization.type) {
      case OrganizationType.BOOK_PROVIDER_ORGANIZATION:
        donationEventsLink = (
          <Link
            to={`/organizations/${organization.id}/donation-events`}
            state={{ organization }}
            className='custom-green'
          >
            {t('Donation Events')}
          </Link>
        );
        donationsLink = (
          <Link
            to={`/organizations/${organization.id}/donations`}
            state={{ organization }}
            className='custom-green'
          >
            {t('Donations')}
          </Link>
        );

        shippingsLink = (
          <Link
            to={`/organizations/${organization.id}/shipping-boxes`}
            state={{ organization }}
            className='custom-green'
          >
            {t('Shippings')}
          </Link>
        );

        break;
      case OrganizationType.LIBRARY:
      case OrganizationType.SCHOOL:
        librariesLink = null;
        booksLink = (
          <Link
            to={`/organizations/${organization.id}/books`}
            state={{ organization }}
            className='custom-green'
          >
            {t('Books').toUpperCase()}
          </Link>
        );
        break;
      default:
        librariesLink = (
          <Link
            to={`/organizations/${organization.id}/libraries`}
            state={{ organization }}
          >
            {t('Libraries')}
          </Link>
        );
        booksLink = (
          <Link
            to={`/organizations/${organization.id}/books`}
            state={{ organization }}
            className='custom-green'
          >
            {t('Books')}
          </Link>
        );
        break;
    }
    const organizationType = getLabelByOptionValue(
      OrganizationTypeOptions,
      organization.type
    );

    const orgName =
      getText(organization.name, organization.nameAm, language) ||
      organization.name;

    return (
      <div>
        <div style={backgroundStyle}>
          <Container
            className='light-blue-white'
            style={{ width: '60%', maxWidth: '720px' }}
          >
            <div
              style={{
                position: 'relative',
                padding: '30px',
              }}
            >
              <Row>
                <Col>
                  <SchoolImage />
                </Col>
                <Col>
                  <ViewTitle name={orgName} resourceName={'Organization'} />

                  {/* <p>{t(organizationType)}</p> */}
                  <p>{t(organization.description)}</p>

                  {addressView}

                  {contactGroupList}
                  {addContactLink}

                  {alpLink && (
                    <p>
                      {alpLink} {t('participant')}
                    </p>
                  )}
                  {donationsLink && <p>{donationsLink}</p>}
                  {donationEventsLink && <p>{donationEventsLink}</p>}
                  {/* {shippingsLink && <p>{shippingsLink}</p>} */}
                  {librariesLink && <p>{librariesLink}</p>}
                  {booksLink && <p>{booksLink}</p>}
                </Col>
              </Row>
              <Row>
                <p>
                  <BackButton onClick={this.handleGoBack} />
                </p>
              </Row>

              {/* Edit icon at the upper right corner */}
              {canEditContent() && (
                <div className='edit-pencil-container'>
                  <a href='' onClick={this.handleEditClick}>
                    <FontAwesomeIcon
                      icon={faPencilAlt}
                      className='edit-pencil'
                    />
                  </a>
                </div>
              )}
            </div>
          </Container>
        </div>
      </div>
    );
  }
}
const HOCOrganizationPage = withRouter(withTranslation()(OrganizationPage));
export default HOCOrganizationPage;

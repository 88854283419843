import React, { Component } from 'react';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import {
  Container,
  Form,
  FormGroup,
  Row,
  Col,
  Input,
  Label,
  Button,
} from 'reactstrap';
import {
  EmptyEdition,
  EmptyWork,
  EmptyPublisher,
  Language,
  LanguageOptions,
  PhysicalFormat,
  PhysicalFormatOptions,
  GradeLevel,
  GradeLevelOptions,
} from './Constant.js';
import { backgroundStyle, inputDisableStyle } from './InlineStyles.js';
import {
  getEdition,
  postEdition,
  searchEditionsByKeyword,
  searchPublishers,
  addEditionToWork,
  deleteEdition,
  isValidApiResponse,
} from './OpenGradaranApi.js';
import {
  FormTitle,
  DeleteButton,
  CloseButton,
  SaveButton,
  BookCoverBig,
  StatusFormGroup,
} from './utils/CustomViewUtil.js';
import { withTranslation } from 'react-i18next';
import { getOpenLibraryMediumCoverUrl } from './OpenLibraryConverter.js';

/* This is a higher order component that
 *  inject a special prop   to our component.
 */
function withRouter(Component) {
  function ComponentWithRouter(props) {
    let params = useParams();
    let navigate = useNavigate();
    let location = useLocation();
    return (
      <Component
        {...props}
        params={params}
        navigate={navigate}
        location={location}
      />
    );
  }
  return ComponentWithRouter;
}

class EditionForm extends Component {
  state = {
    edition: { ...EmptyEdition },
  };

  constructor(props) {
    super(props);
    this.state = {
      work: { ...EmptyWork },
      edition: { ...EmptyEdition },
      editionSearchValue: '',
      editionOptions: [],
      publisher: { ...EmptyPublisher },
      publisherSearchValue: '', // Value entered in the input box
      publisherOptions: [],
      isDropdownVisible: false,
      isLoading: false, // Loading state for API calls
    };
    this.handleEditionChange = this.handleEditionChange.bind(this);
    this.handleEditionInputChange = this.handleEditionInputChange.bind(this);
    this.handleEditionOptionClick = this.handleEditionOptionClick.bind(this);
    this.handlePublisherInputChange =
      this.handlePublisherInputChange.bind(this);
    this.handlePublisherOptionClick =
      this.handlePublisherOptionClick.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);

    this.handleDelete = this.handleDelete.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    try {
      const { state } = this.props.location;

      const id = this.props.params.id;
      const work = state?.work || EmptyWork;
      let edition = state?.edition;
      let publisher = edition?.publisher ||
        state?.publisher || { ...EmptyPublisher };

      if (!edition?.id && id !== 'new') {
        const editionResponse = await (await getEdition(id)).json();

        if (!isValidApiResponse(editionResponse)) {
          throw new Error(`Failed to fetch edition with id: ${id}`);
        }

        publisher = editionResponse.publisher || { ...EmptyPublisher };
      }

      this.setState({
        work,
        edition,
        editionSearchValue: edition.title,
        publisher,
        publisherSearchValue: publisher.name,
      });
    } catch (error) {
      console.error('Error in componentDidMount:', error);
      // Handle error, e.g., set an error state or show an error message
    }
  }

  handleKeyDown = (event) => {
    if (event.key == 'Enter') {
      event.preventDefault(); // Prevent the default behavior of the "Enter" key
    }
  };

  async handlePublisherInputChange(event) {
    event.stopPropagation();
    const value = event.target.value;
    this.setState({ publisherSearchValue: value, isLoading: true });

    this.findAndSetPublisher(value);
    if (value.length > 0) {
      this.setState({ isLoading: false, isDropdownVisible: true });
    } else {
      this.setState({ isLoading: false, isDropdownVisible: false });
    }
  }

  handlePublisherOptionClick(selectedPublisher) {
    let edition = this.state.edition;
    edition.publisher = selectedPublisher;
    this.setState({
      publisher: selectedPublisher,
      publisherSearchValue: selectedPublisher?.name,
      isDropdownVisible: false,
    });
  }

  async findAndSetPublisher(value = '') {
    try {
      const optionsResponse = await (await searchPublishers(value)).json();
      if (isValidApiResponse(optionsResponse.message)) {
        const options = [EmptyPublisher, ...optionsResponse.data.page];
        this.setState({
          publisherOptions: options,
        });
      } else {
        // handle errors
        console.error('Error fetching data:', optionsResponse.message);
      }
    } catch (error) {
      // handle errors
      console.error('Error fetching data:', error);
    }
  }

  async handleEditionInputChange(event) {
    event.stopPropagation();
    const { edition } = this.state;
    const value = event.target.value;
    edition.title = value;
    this.setState({ editionSearchValue: value, isLoading: true });

    this.findAndSetEdition(value);
    if (value.length > 0) {
      this.setState({ isLoading: false, isDropdownVisible: true });
    } else {
      this.setState({ isLoading: false, isDropdownVisible: false });
    }
  }

  handleEditionOptionClick(selectedEdition) {
    this.setState({
      edition: selectedEdition,
      editionSearchValue: selectedEdition?.title,
      isDropdownVisible: false,
    });
  }

  async findAndSetEdition(value = '') {
    try {
      const optionsResponse = await (
        await searchEditionsByKeyword(value)
      ).json();
      if (isValidApiResponse(optionsResponse.message)) {
        const options = [EmptyEdition, ...optionsResponse.data.page];
        this.setState({
          editionOptions: options,
        });
      } else {
        // handle errors
        console.error('Error fetching data:', optionsResponse.message);
      }
    } catch (error) {
      // handle errors
      console.error('Error fetching data:', error);
    }
  }

  handleEditionChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    let edition = this.state.edition;
    edition[name] = value;

    this.setState((state) => {
      return { edition };
    });
  }

  async handleSubmit(event) {
    event.preventDefault();
    const { edition, work } = this.state;
    if (!edition.title) {
      // report an error
      return;
    }

    if (edition.publisher?.name?.trim().length === 0) {
      edition.publisher = null;
    }

    // post edition
    var editionResponse = null;
    if (work?.id) {
      editionResponse = await (await addEditionToWork(work.id, edition)).json();
    } else {
      editionResponse = await (await postEdition(edition)).json();
    }

    if (isValidApiResponse(editionResponse)) {
      this.props.navigate('/editions');
    } else {
      //report error
    }
  }

  async handleDelete(event) {
    event.preventDefault();

    // Display a confirmation dialog before proceeding with the delete action
    const isConfirmed = window.confirm(
      'Are you sure you want to delete this edition?'
    );

    if (!isConfirmed) {
      // If the user cancels, exit the function
      return;
    }

    const { edition } = this.state;
    if (edition.id) {
      try {
        const editionResponse = await deleteEdition(edition.id);
        if (isValidApiResponse(editionResponse)) {
          this.props.navigate('/editions');
        } else {
          // Report invalid API response
          console.error('Invalid API response');
        }
      } catch (error) {
        // Handle network or other errors
        console.error('Error deleting edition:', error);
      }
    } else {
      this.props.navigate(-1);
    }
  }

  handleClose(event) {
    event.preventDefault();
    this.props.navigate(-1);
  }

  render() {
    const { t } = this.props;
    const {
      edition,
      editionSearchValue,
      editionOptions,
      work,
      publisherOptions,
      publisherSearchValue,
      isLoading,
      isDropdownVisible,
    } = this.state;

    let coverUrl = getOpenLibraryMediumCoverUrl(edition.openLibraryCoverId);

    return (
      <div>
        <div style={backgroundStyle}>
          <Container>
            <div class='blue-white'>
              {work?.id && (
                <FormTitle
                  id={work?.id}
                  name={work?.title}
                  resourceName={'Work'}
                />
              )}
              {
                <FormTitle
                  id={edition.id}
                  name={edition.title}
                  resourceName={'Edition'}
                />
              }
              <Form onSubmit={this.handleSubmit}>
                <Row className='mb-3'>
                  <Col>
                    <FormGroup>
                      <BookCoverBig edition={edition} />
                    </FormGroup>
                  </Col>

                  <Col>
                    <FormGroup>
                      <Label htmlFor='id'>{t('ID')}</Label>
                      <Input
                        color='primary'
                        type='text'
                        name='id'
                        id='id'
                        value={edition.id}
                        onChange={this.handleEditionChange}
                        autoComplete='name'
                        readOnly
                        style={inputDisableStyle}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor='openLibraryId'>
                        {t('Open Library ID')}
                      </Label>
                      <Input
                        type='text'
                        name='openLibraryId'
                        id='openLibraryId'
                        value={edition.openLibraryId}
                        onChange={this.handleEditionChange}
                        onKeyDown={this.handleKeyDown}
                        autoComplete='name'
                        placeholder='/books/XXXXXXXXXX'
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor='openLibraryCoverId'>
                        {t('Open Library Cover ID')}
                      </Label>
                      <Input
                        type='text'
                        name='openLibraryCoverId'
                        id='openLibraryCoverId'
                        value={edition.openLibraryCoverId}
                        onChange={this.handleEditionChange}
                        onKeyDown={this.handleKeyDown}
                        autoComplete='name'
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor='coverId'>{t('Cover ID')}</Label>
                      <Input
                        type='text'
                        name='coverId'
                        id='coverId'
                        value={edition.coverId}
                        onChange={this.handleEditionChange}
                        onKeyDown={this.handleKeyDown}
                        autoComplete='name'
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor='externalCoverUrl'>
                        {t('External Cover URL')}
                      </Label>
                      <Input
                        type='text'
                        name='externalCoverUrl'
                        id='externalCoverUrl'
                        value={edition.externalCoverUrl}
                        onChange={this.handleEditionChange}
                        onKeyDown={this.handleKeyDown}
                        autoComplete='name'
                      />
                    </FormGroup>
                  </Col>

                  <Col>
                    <FormGroup>
                      <Label htmlFor='isbn13'>ISBN13</Label>
                      <Input
                        type='text'
                        name='isbn13'
                        id='isbn13'
                        value={edition.isbn13}
                        onChange={this.handleEditionChange}
                        onKeyDown={this.handleKeyDown}
                        autoComplete='name'
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label htmlFor='isbn10'>ISBN10</Label>
                      <Input
                        type='text'
                        name='isbn10'
                        id='isbn_10'
                        value={edition.isbn10}
                        onChange={this.handleEditionChange}
                        onKeyDown={this.handleKeyDown}
                        autoComplete='name'
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <FormGroup>
                  <Label htmlFor='title'>{t('Title')}</Label>
                  <Input
                    type='text'
                    value={editionSearchValue}
                    onChange={this.handleEditionInputChange}
                    onKeyDown={this.handleKeyDown}
                    className='form-control'
                    placeholder={t('enter edition title')}
                  />
                  {isDropdownVisible && !isLoading && (
                    <div className='dropdown-menu show'>
                      {editionOptions.map((option) => (
                        <Button
                          key={option.id}
                          className='dropdown-item'
                          onClick={() => this.handleEditionOptionClick(option)}
                        >
                          {option.title}
                        </Button>
                      ))}
                    </div>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label htmlFor='subtitle'>{t('Subtitle')}</Label>
                  <Input
                    type='text'
                    name='subtitle'
                    id='subtitle'
                    value={edition.subtitle}
                    onChange={this.handleEditionChange}
                    onKeyDown={this.handleKeyDown}
                    autoComplete='name'
                  />
                </FormGroup>

                <FormGroup>
                  <Label htmlFor='full_title'>{t('Full Title')}</Label>
                  <Input
                    type='text'
                    name='fullTitle'
                    id='fullTitle'
                    value={edition.fullTitle}
                    onChange={this.handleEditionChange}
                    onKeyDown={this.handleKeyDown}
                    autoComplete='name'
                  />
                </FormGroup>
                <Row className='mb-3'>
                  <Col>
                    <FormGroup>
                      <Label htmlFor='language'>{t('Language')}</Label>
                      <select
                        value={edition.language?.toUpperCase() || ''}
                        defaultValue={Language.ENGLISH}
                        onChange={this.handleEditionChange}
                        onKeyDown={this.handleKeyDown}
                        className='form-control'
                        name='language'
                        id='language'
                      >
                        {LanguageOptions.map((option) => (
                          <option value={option.value}>{option.label}</option>
                        ))}
                      </select>
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor='pageCount'>{t('Page Count')}</Label>
                      <Input
                        type='text'
                        name='pageCount'
                        id='pageCount'
                        value={edition.pageCount}
                        onChange={this.handleEditionChange}
                        onKeyDown={this.handleKeyDown}
                        autoComplete='name'
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor='physicalFormat'>
                        {t('Physical Format')}
                      </Label>
                      <select
                        value={edition.physicalFormat}
                        defaultValue={PhysicalFormat.BOOK_PAPERBACK}
                        onChange={this.handleEditionChange}
                        onKeyDown={this.handleKeyDown}
                        className='form-control'
                        name='physicalFormat'
                        id='physicalFormat'
                      >
                        {PhysicalFormatOptions.map((option) => (
                          <option value={option.value}>{option.label}</option>
                        ))}
                      </select>
                    </FormGroup>
                  </Col>

                  <Col>
                    <FormGroup>
                      <Label htmlFor='publisher'>{t('Publisher')}</Label>
                      <Input
                        type='text'
                        value={publisherSearchValue}
                        onChange={this.handlePublisherInputChange}
                        onKeyDown={this.handleKeyDown}
                        className='form-control'
                        placeholder={t('Search...')}
                      />
                      {isDropdownVisible && !isLoading && (
                        <div className='dropdown-menu show'>
                          {publisherOptions.map((option) => (
                            <Button
                              key={option.id}
                              className='dropdown-item'
                              onClick={() =>
                                this.handlePublisherOptionClick(option)
                              }
                            >
                              {option.name}
                            </Button>
                          ))}
                        </div>
                      )}
                    </FormGroup>

                    <FormGroup>
                      <Label htmlFor='publishPlace'>{t('Publish Place')}</Label>
                      <Input
                        type='text'
                        name='publishPlace'
                        id='publishPlace'
                        value={edition.publishPlace}
                        onChange={this.handleEditionChange}
                        onKeyDown={this.handleKeyDown}
                        autoComplete='name'
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label htmlFor='publishDate'>{t('Publish Date')}</Label>
                      <Input
                        type='text'
                        name='publishDate'
                        id='publishDate'
                        value={edition.publishDate}
                        onChange={this.handleEditionChange}
                        onKeyDown={this.handleKeyDown}
                        autoComplete='name'
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row className='mb-3'>
                  <Col>
                    <FormGroup>
                      <Label htmlFor='startGradeLevel'>
                        {t('Start Grade Level')}
                      </Label>
                      <select
                        value={edition.gradeLevelStart}
                        defaultValue={GradeLevel.UNDEFINED}
                        onChange={this.handleEditionChange}
                        onKeyDown={this.handleKeyDown}
                        className='form-control'
                        name='gradeLevelStart'
                        id='gradeLevelStart'
                      >
                        {GradeLevelOptions.map((option) => (
                          <option value={option.value}>{option.label}</option>
                        ))}
                      </select>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label htmlFor='endGradeLevel'>
                        {t('End Grade Level')}
                      </Label>
                      <select
                        value={edition.gradeLevelEnd}
                        defaultValue={GradeLevel.UNDEFINED}
                        onChange={this.handleEditionChange}
                        onKeyDown={this.handleKeyDown}
                        className='form-control'
                        name='gradeLevelEnd'
                        id='gradeLevelEnd'
                      >
                        {GradeLevelOptions.map((option) => (
                          <option value={option.value}>{option.label}</option>
                        ))}
                      </select>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label htmlFor='readingAgeStart'>
                        {t('Reading Age Start')}
                      </Label>
                      <Input
                        type='text'
                        name='readingAgeStart'
                        id='readingAgeStart'
                        value={edition.readingAgeStart}
                        onChange={this.handleEditionChange}
                        onKeyDown={this.handleKeyDown}
                        autoComplete='name'
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label htmlFor='readingAgeEnd'>
                        {t('Reading Age End')}
                      </Label>
                      <Input
                        type='text'
                        name='readingAgeEnd'
                        id='readingAgeEnd'
                        value={edition.readingAgeEnd}
                        onChange={this.handleEditionChange}
                        onKeyDown={this.handleKeyDown}
                        autoComplete='name'
                      />
                    </FormGroup>
                  </Col>
                </Row>

                {/* <FormGroup>
                <Button color='primary' type='submit'>
                  Save
                </Button>{' '}
                <Button color='secondary' tag={Link} to='/authors'>
                  Cancel
                </Button>
              </FormGroup> */}
                <StatusFormGroup
                  status={edition.status}
                  handleChange={this.handleEditionChange}
                />
                <FormGroup>
                  <SaveButton />
                  <CloseButton onClick={this.handleClose} />
                  <DeleteButton onClick={this.handleDelete} />
                </FormGroup>
              </Form>
            </div>
          </Container>
        </div>
      </div>
    );
  }
}
const HOCEditionForm = withRouter(withTranslation()(EditionForm));
export default HOCEditionForm;

import React from 'react';
import { QRCodeCanvas } from 'qrcode.react';

const AlpQRCode = () => {
  const qrValue = `https://armenianlibraryproject.org`;
  const qrCodeSize = 400; // Size of the QR code itself
  const textRadius = qrCodeSize * 0.55; // Slightly larger than QR code to render text outside
  const padding = 15; // Fixed padding for consistent spacing
  const svgSize = qrCodeSize + 2 * (textRadius + padding); // Total SVG size

  return (
    <div
      style={{
        position: 'relative',
        width: svgSize,
        height: svgSize,
      }}
    >
      {/* QR Code */}
      <QRCodeCanvas
        value={qrValue}
        size={qrCodeSize}
        style={{
          position: 'absolute',
          top: (svgSize - qrCodeSize) / 2, // Center the QR code
          left: (svgSize - qrCodeSize) / 2,
        }}
      />

      {/* Square Path for Text Around the QR Code */}
      <svg
        width={svgSize}
        height={svgSize}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          pointerEvents: 'none',
        }}
      ></svg>
    </div>
  );
};

export default AlpQRCode;
